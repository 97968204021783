import React from 'react';
import { Box, HStack, VStack, Text, Button } from '@chakra-ui/react';

export interface ICoupon {
  _id: string;
  name: string;
  quantity?: number;
}

interface IDiscountCouponCard extends ICoupon {
  // value: number;
  // setValue: (value: number) => void;
  onClickRemove: () => void;
}

const DiscountCouponCard: React.FC<IDiscountCouponCard> = ({
  name,
  quantity,
  // value,
  // setValue,
  onClickRemove,
}) => {
  return (
    <Box
      style={{ border: '1px solid #E2E8F0', borderRadius: '1rem' }}
      width="100%"
      px={6}
      py={8}
    >
      <HStack align="center" justify="space-between">
        <VStack align="flex-start">
          <HStack align="center">
            <Text color="#A8A8A8" fontSize="xs" minWidth={110}>
              할인권명
            </Text>
            <Text color="rgba(0, 0, 0, 0.85)" fontSize="md">
              {name}
            </Text>
          </HStack>
          {quantity && (
            <HStack align="center">
              <Text color="#A8A8A8" fontSize="xs" minWidth={110}>
                보유수량
              </Text>
              <Text color="rgba(0, 0, 0, 0.85)" fontSize="md">
                {quantity.toLocaleString()} 매
              </Text>
            </HStack>
          )}
          {/* <HStack align="center">
          <Text color="#A8A8A8" fontSize="xs" minWidth={110}>
            할인적용
          </Text>
          <HStack align="center">
            <IconButton onClick={() => setValue(value + 1)}>
              <AiOutlinePlus size="1.05rem" />
            </IconButton>
            <Input
              style={{
                border: '1px solid rgba(0, 0, 0, 0.85)',
                borderRadius: 8,
                width: 80,
                textAlign: 'center',
              }}
              size="sm"
              type="number"
              value={value}
              onChange={(e) => setValue(Number(e.target.value))}
            />
            <IconButton
              onClick={value - 1 >= 0 ? () => setValue(value - 1) : undefined}
            >
              <AiOutlineMinus size="1.05rem" />
            </IconButton>
          </HStack>
        </HStack> */}
        </VStack>
        <Button onClick={onClickRemove}>적용 취소</Button>
      </HStack>
    </Box>
  );
};

export default DiscountCouponCard;

// const IconButton = styled.button`
//   padding: 6px;
//   border: 1px solid rgba(0, 0, 0, 0.85);
//   border-radius: 50%;
// `;
