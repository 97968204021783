import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import WebFont from 'webfontloader';

import App from './App';
import { NesProvider } from './contexts/NesContext';
import reportWebVitals from './reportWebVitals';

WebFont.load({
  custom: {
    families: ['LGSmHa'],
    urls: ['/fonts/LGSmHa.css']
  }
})

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <NesProvider>
        <App />
      </NesProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
