import React, { useMemo } from 'react';
import { Center, Spinner } from '@chakra-ui/react';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';

import ReservationCard, {
  IReservation,
} from '../../components/ReservationCard';
import ServiceWrapper from '../../components/ServiceWrapper';
import AbsoluteButton from '../../components/AbsoluteButton';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../../recoil/auth';

const Reservations = () => {
  const history = useHistory();
  const userInfo = useRecoilValue(userInfoState);
  const { data, mutate } = useSWR('/reservations');

  const isYongsanCustom = useMemo(
    () => userInfo?.site._id === 'LG_UPLUS_YONGSAN',
    [userInfo?.site._id]
  );

  return (
    <ServiceWrapper
      title={isYongsanCustom ? '주차할인' : '방문예약'}
      fullHeight
    >
      {!data ? (
        <Center flex={1}>
          <Spinner />
        </Center>
      ) : (
        data.length === 0 && (
          <Center flex={1}>
            등록된 {isYongsanCustom ? '주차할인' : '방문예약'}이 없습니다.
          </Center>
        )
      )}
      {data?.map?.(
        ({ _id, vehicle, user, startAt, endAt, status }: IReservation) => (
          <ReservationCard
            _id={_id}
            key={_id}
            user={user}
            vehicle={vehicle}
            startAt={startAt}
            endAt={endAt}
            mutate={mutate}
            status={status}
          />
        )
      )}
      <AbsoluteButton
        onClick={() => history.push(PRIVATE_ROUTE.RESERVATION_SUBMIT)}
      >
        등록하기
      </AbsoluteButton>
    </ServiceWrapper>
  );
};

export default Reservations;
