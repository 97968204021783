import { extendTheme } from '@chakra-ui/react';

export const formStyle = {
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  borderBottom: '1px solid #DBE9EE',
  borderRadius: 0,
  fontSize: '0.95rem',
  padding: '0 0.25rem',
};

const theme = extendTheme({
  fonts: {
    body: 'LGSmHa',
    heading: 'LGSmHa',
    mono: 'LGSmHa',
  },
  colors: {
    brand: '#cd0f46',
    darkRed: '#bf0a3e',
    mildRed: '#ee4472',
    green: '#39B2AB',
    redCheckbox: {
      500: '#cd0f46',
    },
  },
  components: {
    Button: {
      baseStyle: {},
      sizes: {
        large: {
          height: '3.2rem',
          width: '100%',
          fontWeight: 'normal',
        },
        natural: {
          padding: 7,
          paddingTop: '1.5rem',
          paddingBottom: '1.5rem',
          height: 'auto',
        },
      },
      variants: {
        brand: {
          background: 'brand',
          color: 'white',
          _hover: {
            background: 'darkRed',
          },
          _active: {
            background: 'darkRed',
          },
        },
        outline: {
          background: 'white',
          color: 'brand',
          borderColor: 'brand',
          borderWidth: 1,
          padding: 7,
          _hover: {
            background: '#f8f9fa',
            borderColor: 'darkRed',
          },
          _active: {
            background: '#f8f9fa',
            borderColor: 'darkRed',
          },
        },
      },
    },
    Input: {
      defaultProps: {
        size: 'lg',
      },
    },
  },
});

export default theme;
