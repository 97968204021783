import React, { useMemo } from 'react';
import styled from 'styled-components';
import DatePicker, {
  DayRange,
  RenderInputProps,
} from 'react-modern-calendar-datepicker';
import { Input } from '@chakra-ui/react';
import { HiOutlineCalendar } from 'react-icons/hi';
import { formStyle } from '../theme';
import kr from '../constance/datepicker-kr';

interface CalendarRowProps {
  value: DayRange;
  onChange: (value: DayRange) => void;
  isEditable?: boolean;
}

const CalendarRow: React.FC<CalendarRowProps> = ({
  value,
  onChange,
  isEditable = true,
}) => {
  const todayDate = useMemo(() => {
    const datetime = new Date();
    return {
      year: datetime.getFullYear(),
      month: datetime.getMonth() + 1,
      day: datetime.getDate(),
    };
  }, []);

  const displayValue = useMemo(
    () =>
      value.to && value.from
        ? `${value?.from.year}/${value?.from.month}/${value?.from.day} ~ ${value?.to.year}/${value?.to.month}/${value?.to.day}`
        : '기간을 선택해주세요.',
    [value]
  );

  const renderCustomInput = ({ ref }: RenderInputProps) => (
    <UnselectableInput
      readOnly
      // @ts-ignore
      ref={ref}
      style={formStyle}
      placeholder="기간을 선택해주세요."
      value={displayValue}
    />
  );

  return (
    <CalendarRowWrapper>
      {isEditable ? (
        <DatePicker
          locale={kr}
          value={value}
          renderInput={renderCustomInput}
          onChange={onChange}
          colorPrimary="#cd0f46"
          colorPrimaryLight="#ee4472"
          minimumDate={todayDate}
        />
      ) : (
        <Input style={formStyle} disabled={true} defaultValue={displayValue} />
      )}
      <HiOutlineCalendar size="2rem" />
    </CalendarRowWrapper>
  );
};

export default CalendarRow;

const CalendarRowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const UnselectableInput = styled(Input)`
  --webkit-user-select: none !important;
  --moz-user-select: none !important;
  user-select: none !important;
`;
