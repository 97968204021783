import React from 'react';
import { useHistory } from 'react-router-dom';
import { HiCheckCircle } from 'react-icons/hi';
import { Button, Center, Heading, Text } from '@chakra-ui/react';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import StackWrapper from '../../components/StackWrapper';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';

const PaymentSuccess: React.FC = () => {
  const history = useHistory();

  const [{ price, transactionId }] = useQueryParams({
    price: withDefault(NumberParam, 0),
    transactionId: withDefault(StringParam, ''),
  });

  return (
    <StackWrapper title="결제 완료" hideBack>
      <>
        <Center flex={1} flexDirection="column" align="center">
          <HiCheckCircle
            color="#cd0f46"
            size="6.5rem"
            style={{
              marginBottom: '1.2rem',
            }}
          />
          <Heading>결제 완료</Heading>
          <Text
            color="rgba(0, 0, 0, 0.95)"
            align="center"
            marginTop={4}
            fontSize="md"
          >
            총 <strong>{price.toLocaleString()}</strong>원 결제되었습니다.
            <br />
            <Text marginTop={1} marginBottom={8}>
              주문번호: {transactionId || '-'}
            </Text>
          </Text>
          <Button
            marginBottom="82px"
            variant="brand"
            onClick={() => history.push(PRIVATE_ROUTE.MAIN)}
          >
            메인으로
          </Button>
        </Center>
      </>
    </StackWrapper>
  );
};

export default PaymentSuccess;
