import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { HiOutlineTicket, HiOutlineUserGroup } from 'react-icons/hi';
import { startOfDay, endOfDay } from 'date-fns';

import useAxios from '../../hooks/useAxios';
import { userInfoState } from '../../recoil/auth';
import ServiceWrapper from '../../components/ServiceWrapper';
import ColumnCard from '../../components/ColumnCard';
import { format } from '../../utils/date';
import { VEHICLE_REGEX, PHONE_REGEX } from '../../utils/regex';
import { phoneNormalizer } from '../../utils/normalizers';
import BusinessFooter from '../../components/BusinessFooter';
import PDFPage from '../../components/PDFPage';

const Message = (props: any) => (
  <Text fontSize="xs" color="brand" marginTop={1} {...props} />
);

const PrimaryButton = (props: any) => (
  <Button variant="brand" size="natural" {...props} _hover={{}} />
);

const OutlineButton = (props: any) => (
  <Button
    variant="outline"
    padding={7}
    style={{
      padding: 0,
      height: 'auto',
      boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)',
    }}
    {...props}
  />
);

const StyledHeading: React.FC<ComponentProps<typeof Heading>> = ({
  children,
  ...props
}) => (
  <Heading size="md" marginBottom={3} {...props}>
    {children}
  </Heading>
);

const StyledSelect = ({ style, ...props }: any) => (
  <Select
    size="lg"
    style={{
      ...style,
      fontSize: '0.95rem',
      fontWeight: 'bold',
      height: '3.5rem',
      border: 0,
      boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)',
    }}
    {...props}
  />
);

const SearchInput = (props: any) => (
  <InputGroup marginBottom={2}>
    <Input
      style={{
        backgroundColor: '#F7F8F9',
        border: 0,
        fontSize: '0.95rem',
      }}
      _placeholder={{
        color: '#99999A',
      }}
      {...props}
    />
  </InputGroup>
);

const AlignedVStack: React.FC<ComponentProps<typeof VStack>> = ({
  children,
  ...props
}) => (
  <VStack align="flex-start" width="100%" py={4} {...props}>
    {children}
  </VStack>
);

const Main = () => {
  const api = useAxios();
  const history = useHistory();
  const location = useLocation<{ vehicle: string }>();
  const userInfo = useRecoilValue(userInfoState);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [firstPlateNumber, setFirstPlateNumber] = useState('');
  const [secondPlateNumber, setSecondPlateNumber] = useState('');
  const [firstCouponSelect, setFirstCouponSelect] = useState('');
  const [secondCouponSelect, setSecondCouponSelect] = useState('');
  const [isApplyCouponLoading, setIsApplyCouponLoading] = useState(false);

  const isSiliconBridge = useMemo(
    () => userInfo?.site.region === 'siliconbridge',
    [userInfo?.site.region]
  );

  useEffect(() => {
    if (location.state?.vehicle) {
      setSecondPlateNumber(location.state?.vehicle);
    }
  }, [location.state?.vehicle]);

  useEffect(() => {
    return () => {
      history.location.state = {};
    };
  }, [history.location]);

  const toast = useToast({
    title: '할인권 적용에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });

  const { data: couponData, mutate: mutateCoupons } = useSWR('/coupons');
  const { data: ticketData, error: errorSeasonTickets } =
    useSWR('/season-tickets/me');

  const couponOptions = useMemo<{ value: string; label: string }[]>(
    () =>
      couponData
        ? Object.keys(couponData).map((key) => {
            const currentCoupon = couponData?.[key];
            return {
              value: key,
              label: `${currentCoupon?.name || '이름 없음'} (${(
                currentCoupon?.quantity || 0
              ).toLocaleString()}장 남음)`,
            };
          })
        : [],
    [couponData]
  );

  const handleComplexRegisterVehicle = async () => {
    history.push('/reservation-submit', {
      vehicle: firstPlateNumber,
      phone: phone,
      discountCouponKey: firstCouponSelect || undefined,
    });
  };

  const handleRegisterVehicle = async () => {
    if (!firstPlateNumber) {
      toast({
        title: '방문예약 등록에 실패했습니다.',
        description: '차량번호를 입력하세요.',
      });
      return;
    }
    if (!phone) {
      toast({
        title: '방문예약 등록에 실패했습니다.',
        description: '전화번호를 입력하세요.',
      });
      return;
    }
    if (!firstPlateNumber.match(VEHICLE_REGEX)) {
      toast({
        title: '방문예약 등록에 실패했습니다.',
        description:
          '차량번호를 제대로 입력해주세요! 띄어쓰기는 허용되지 않습니다.',
      });
      return;
    }
    if (isNaN(Number(phone))) {
      toast({
        title: '방문예약 등록에 실패했습니다.',
        description: '전화번호는 숫자만 입력하세요.',
      });
      return;
    }
    if (phone.length > 11 || !phone.match(PHONE_REGEX)) {
      toast({
        title: '방문예약 등록에 실패했습니다.',
        description: '잘못된 형식의 전화번호입니다.',
      });
      return;
    }
    try {
      await api.post('/reservations', {
        vehicle: firstPlateNumber,
        discountCouponKey: firstCouponSelect || undefined,
        visitLimit: 1,
        remarks: '앱 방문등록',
        user: { name, phone },
        startAt: startOfDay(new Date()).getTime(),
        endAt: endOfDay(new Date()).getTime(),
      });
      toast({
        title: '방문예약 등록에 성공했습니다.',
        description: '정상적으로 등록되었습니다.',
        status: 'success',
      });
      setName('');
      setPhone('');
      setFirstPlateNumber('');
      setFirstCouponSelect('');

      mutateCoupons();
    } catch (err: any) {
      if (!err.response) {
        toast({ description: '네트워크에 문제가 있습니다.' });
        return;
      }
      toast({
        title: '방문예약 등록에 실패했습니다.',
        description: err.response.data.message,
      });
    }
  };

  const handleApplyCoupon = async () => {
    if (secondPlateNumber.match(VEHICLE_REGEX) === null) {
      toast({
        title: '차량 할인권 적용을 실패했습니다.',
        description: '차량번호를 제대로 입력해 주세요!',
      });
      return;
    }

    if (!secondCouponSelect) {
      toast({
        title: '차량 할인권 적용을 실패했습니다.',
        description: '할인권을 선택해주세요.',
      });
      return;
    }

    try {
      setIsApplyCouponLoading(true);
      const { data } = await api.get(
        `/settlements?plateNumber=${secondPlateNumber}`
      );
      await api.post('/coupons/use', {
        vehicleAccess: data._id,
        discountCouponKey: secondCouponSelect,
      });
      toast({
        title: '정상적으로 처리되었습니다.',
        description: '할인권 등록이 완료되었습니다.',
        status: 'success',
      });
      setSecondPlateNumber('');
      setSecondCouponSelect('');
    } catch (err: any) {
      if (err?.response?.status === 404) {
        toast({
          title: '차량번호를 찾을 수 없습니다.',
          description: '주차중이 아닌 차량입니다.',
        });
      } else {
        toast({
          title: '할인권 등록에 실패했습니다.',
          description:
            err?.response?.data?.message ||
            '할인권 적용 중 오류가 발생했습니다.',
        });
      }
    } finally {
      mutateCoupons();
      setIsApplyCouponLoading(false);
    }
  };

  const isYongsanCustom = useMemo(
    () => userInfo?.site._id === 'LG_UPLUS_YONGSAN',
    [userInfo?.site._id]
  );

  const isThemepolisCustom = useMemo(
    () => userInfo?.site._id === 'SBI_EYEVACS_0009',
    [userInfo?.site._id]
  );

  const [noticeOpen, setNoticeOpen] = useState(false);

  return (
    <ServiceWrapper>
      <VStack align="flex-start">
        <Heading size="lg">
          {userInfo?.site?.name}
          <br />
          {`${userInfo?.info.name}님, 환영합니다.`}
        </Heading>
        <Divider paddingTop={4} />
        {isThemepolisCustom && (
          <AlignedVStack>
            <StyledHeading onClick={() => setNoticeOpen(true)}>
              주차장 이용안내 (필독)
            </StyledHeading>
            <PDFPage
              open={noticeOpen}
              onClose={() => setNoticeOpen(false)}
              href="https://eyevacs.github.io/data/pdf/themepolis_notice.pdf"
            />
          </AlignedVStack>
        )}
        {!isSiliconBridge && !isYongsanCustom && (
          <AlignedVStack>
            <StyledHeading>주차 할인권 적용</StyledHeading>
            <SearchInput
              placeholder="차량번호 선택"
              value={secondPlateNumber}
              onClick={() => history.push('/main/vehicle-select')}
              readOnly
            />
            <Flex direction="row" width="100%">
              <StyledSelect
                value={secondCouponSelect}
                onChange={(e: any) => setSecondCouponSelect(e.target.value)}
              >
                <option value="">할인권을 선택해주세요.</option>
                {couponOptions.map(({ label, value }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </StyledSelect>
              <PrimaryButton
                marginLeft={3}
                onClick={handleApplyCoupon}
                isLoading={isApplyCouponLoading}
              >
                적용
              </PrimaryButton>
            </Flex>
            <Text fontSize="xs" fontWeight="bold" paddingTop={2}>
              {couponData?.length > 0 &&
                `보유량 ${couponData[String(secondCouponSelect)]?.quantity}건`}
            </Text>
          </AlignedVStack>
        )}
        {!isSiliconBridge && (
          <AlignedVStack>
            <StyledHeading>
              {isYongsanCustom ? '주차 할인' : '방문 차량 등록'}
            </StyledHeading>
            <Flex direction="row" width="100%" pb={1}>
              <Flex direction="column" flex="1">
                <SearchInput
                  placeholder="차량번호를 빈칸없이 정확히 입력해 주세요."
                  value={firstPlateNumber}
                  onChange={(e: any) => setFirstPlateNumber(e.target.value)}
                />
                <SearchInput
                  placeholder="전화번호 01012345678"
                  value={phone}
                  onChange={(e: any) => {
                    setPhone(phoneNormalizer(e.target.value));
                  }}
                />
                {isYongsanCustom && (
                  <SearchInput
                    placeholder="이름을 입력해 주세요."
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                )}
                <StyledSelect
                  value={firstCouponSelect}
                  onChange={(e: any) => setFirstCouponSelect(e.target.value)}
                >
                  <option value="">선택안함</option>
                  {couponOptions.map(({ label, value }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </StyledSelect>
              </Flex>
              <Flex direction="column" marginLeft={3}>
                <PrimaryButton flex="1" onClick={handleRegisterVehicle}>
                  간편예약
                </PrimaryButton>
                <OutlineButton
                  marginTop={2}
                  style={{ height: '3.5rem' }}
                  onClick={handleComplexRegisterVehicle}
                >
                  상세예약
                </OutlineButton>
              </Flex>
            </Flex>
            <Message color="rgba(0, 0, 0, 0.8)">
              오늘 외에 다른 일정 방문 등록 필요 시 상세예약 버튼을
              클릭해주세요.
            </Message>
            <Text fontSize="xs" fontWeight="bold" paddingTop={2}>
              {couponData?.length > 0 &&
                `보유량 ${couponData[String(firstCouponSelect)]?.quantity}건`}
            </Text>
          </AlignedVStack>
        )}
        {!isYongsanCustom && (
          <AlignedVStack>
            <StyledHeading>정기권 신청 현황</StyledHeading>
            <ColumnCard
              columns={[
                ...[
                  !errorSeasonTickets &&
                  ticketData?.startAt &&
                  ticketData?.endAt
                    ? {
                        icon: (
                          <HiOutlineTicket size="1.65rem" color="#707070" />
                        ),
                        field: '정기권 이용 기간',
                        // value: '2020. 9. 1. ~ 2020. 9. 31',
                        value: `${
                          ticketData?.startAt
                            ? format(ticketData.startAt, false)
                            : ''
                        } ~ ${
                          ticketData?.endAt
                            ? format(ticketData.endAt, false)
                            : ''
                        }`,
                      }
                    : {
                        icon: (
                          <HiOutlineTicket size="1.65rem" color="#707070" />
                        ),
                        field: '정기권 신청 상태',
                        value:
                          (!errorSeasonTickets &&
                            (
                              {
                                '01': '신규',
                                '02': '등록대기',
                                '03': '사용대기',
                                '04': '사용중',
                                '05': '사용종료',
                                '06': '삭제대기',
                                '07': '결제대기',
                                '08': '후불결제대기',
                                '09': '후불결제완료',
                              } as { [key: string]: string }
                            )[ticketData?.status]) ||
                          '-',
                      },
                ],
                {
                  icon: <HiOutlineUserGroup size="1.65rem" color="#707070" />,
                  field: '대기자 순번',
                  value:
                    (!errorSeasonTickets &&
                      ticketData?.waitingSequence?.toLocaleString()) ||
                    '-',
                },
              ]}
            />
          </AlignedVStack>
        )}
        <BusinessFooter
          color="#707070"
          style={{ marginLeft: '-0.5rem', marginRight: '-0.5rem' }}
        />
        {/* <AlignedVStack>
        <StyledHeading>불만 유형</StyledHeading>
        <Flex direction="row" width="100%">
          <StyledSelect>
            <option value={1}>불만 유형</option>
          </StyledSelect>
          <OutlineButton marginLeft={3}>
            <AddIcon color="brand" />
          </OutlineButton>
        </Flex>
      </AlignedVStack> */}
      </VStack>
    </ServiceWrapper>
  );
};

export default Main;
