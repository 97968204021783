import React, { useMemo } from 'react';
import {
  FiHome,
  FiCalendar,
  FiMoreVertical,
  FiCreditCard,
} from 'react-icons/fi';
import { TiTicket } from 'react-icons/ti';
import { HiOutlineTicket } from 'react-icons/hi';
import { VStack, Text } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../recoil/auth';

interface ITab {
  route: string;
  icon?: React.ReactNode;
  title: string;
  width: string;
}

const Tab: React.FC<ITab> = ({ title, route, icon = null, width }) => {
  const isSelected = route === window.location.pathname;

  return (
    <ReactLink to={route} style={{ width, textDecoration: 'none' }}>
      <VStack color={isSelected ? 'brand' : '#99999A'}>
        {icon}
        <Text fontSize="xs">{title}</Text>
      </VStack>
    </ReactLink>
  );
};

const TabBar = () => {
  const userInfo = useRecoilValue(userInfoState);

  const isSiliconBridge = useMemo(
    () => userInfo?.site.region === 'siliconbridge',
    [userInfo?.site.region]
  );
  const isYongsanCustom = useMemo(
    () => userInfo?.site._id === 'LG_UPLUS_YONGSAN',
    [userInfo?.site._id]
  );

  const tabs = [
    {
      title: '홈',
      icon: <FiHome size="1.25rem" />,
      route: '/main',
    },
    {
      title: '내차정산',
      icon: <FiCreditCard size="1.25rem" />,
      route: '/calculate',
      hidden: isYongsanCustom || isSiliconBridge,
    },
    {
      title: '할인권',
      icon: <TiTicket size="1.25rem" />,
      route: '/discount-coupons',
      hidden: isSiliconBridge,
    },
    {
      title: '정기권',
      icon: <HiOutlineTicket size="1.25rem" />,
      route: '/season-tickets',
      hidden: isYongsanCustom,
    },
    {
      title: isYongsanCustom ? '주차할인' : '방문예약',
      icon: <FiCalendar size="1.25rem" />,
      route: '/reservations',
      hidden: isSiliconBridge,
    },
    {
      title: '더보기',
      icon: <FiMoreVertical size="1.25rem" />,
      route: '/more',
    },
  ];

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        bottom: 0,
        padding: '1rem 0',
        height: 80,
      }}
    >
      {tabs
        .filter(({ hidden }) => !hidden)
        .map(({ route, ...props }, _, a) => (
          <Tab
            key={route}
            route={route}
            {...props}
            width={`calc(100% / ${a.length})`}
          />
        ))}
    </div>
  );
};

export default TabBar;
