import React, { useMemo } from 'react';
import { Box, Divider, Button } from '@chakra-ui/react';
import styled from 'styled-components';
import { format } from '../../utils/date';
import { useHistory } from 'react-router-dom';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';

export interface IFeedbackItem {
  _id: string;
  title: string;
  content: string;
  createdAt: string;
  childrenCount: number;
  voc: any;
}

const FeedbackItem: React.FC<IFeedbackItem> = ({
  _id,
  title,
  content,
  childrenCount,
  createdAt,
  voc,
}) => {
  const history = useHistory();
  const count = useMemo(
    () => (childrenCount ?? 0) + (voc?.result?.defailForCustomer ? 1 : 0),
    [childrenCount, voc]
  );
  return (
    <>
      <Button
        py={8}
        style={{
          width: '100%',
          backgroundColor: 'transparent',
        }}
        onClick={() => history.push(`${PRIVATE_ROUTE.FEEDBACKS}/view/${_id}`)}
      >
        <Box
          flex="1"
          textAlign="left"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '0.8rem',
            minWidth: 'calc(100% - 1.8rem)',
          }}
        >
          <Title>{title}</Title>
          <CreatedAt>{format(createdAt, false)}</CreatedAt>
          <Box as="span" color="brand">
            피드백 {count.toLocaleString()}개
          </Box>
        </Box>
      </Button>
      <Divider />
    </>
  );
};

export default FeedbackItem;

const Title = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  line-height: 2.5rem;
`;

const CreatedAt = styled.span`
  color: #a1a6ad;
  font-weight: normal;
  margin-right: 6px;
`;
