import { useEffect, useRef, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import StackWrapper from '../../components/StackWrapper';
import {
  HStack,
  VStack,
  Input,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import useAxios from '../../hooks/useAxios';
import useOnScreen from '../../utils/useOnScreen';
import VehicleSelectCard from './components/VehicleSelectCard';

const VehicleSelect = () => {
  const api = useAxios();
  const vehicleInputRef = useRef<HTMLInputElement>(null);
  const [searchVehicleValue, setSearchVehicleValue] = useState<string>('');
  const targetRef = useRef<HTMLSpanElement>(null);
  const isVisibleTargetRef = useOnScreen(targetRef);

  const { data: pageData, setSize } = useSWRInfinite(
    (pageIndex) => {
      if (!searchVehicleValue) return null;
      return `/vehicles?vehicle=${searchVehicleValue}&page=${pageIndex + 1}&count=10`;
    },
    async (url: string, params: object) => {
      const res = await api.get(url, { params });
      return res.data?.docs || res.data;
    },
    { revalidateAll: true } // 할인권 내용 변할 수 있음!
  );

  useEffect(() => {
    if (isVisibleTargetRef) {
      setSize(size => size + 1);
    }
  }, [isVisibleTargetRef, setSize]);

  const handleSearch = () => {
    if (vehicleInputRef.current) {
      setSearchVehicleValue(vehicleInputRef.current.value);
    }
  };

  return (
    <StackWrapper title="차량 선택">
      <HStack>
        <Input
          autoFocus
          style={{
            backgroundColor: '#F7F8F9',
            border: 0,
            fontSize: '0.95rem',
          }}
          _placeholder={{
            color: '#99999A',
          }}
          placeholder="차량 번호를 입력해주세요."
          ref={vehicleInputRef}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
        />
        <Button variant="brand" padding={5} onClick={handleSearch}>
          검색
        </Button>
      </HStack>
      <VStack marginTop={5} spacing={4}>
        {pageData &&
          (!!pageData?.[0]?.length ? (
            pageData?.map((data) =>
              data.map((data: any) => (<VehicleSelectCard data={data} />))
            )
          ) : (
            <Alert status="warning">
              <AlertIcon />
              검색된 차량이 없습니다.
            </Alert>
          ))}
        <span ref={targetRef} />
      </VStack>
    </StackWrapper>
  );
};

export default VehicleSelect;
