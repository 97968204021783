import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { VStack, Button, HStack, Heading, useToast } from '@chakra-ui/react';
import useSWR from 'swr';
import styled from 'styled-components';

import LargeDiscountCouponCard, {
  ICouponKey,
} from './components/LargeDiscountCouponCard';
import StackWrapper from '../../components/StackWrapper';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';

const DiscountCouponKeys = () => {
  const history = useHistory();
  const toast = useToast({
    title: '잠깐!',
    description: '구매할 할인권을 선택해 주세요.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });

  const { data } = useSWR('/coupon-keys');
  const [receipt, setReceipt] = useState<{ [key: string]: number }>({});
  const totalPrice = useMemo<number>(
    () =>
      Object.keys(receipt).reduce(
        (total, key) =>
          total +
          (data?.coupons?.find((v: ICouponKey) => v._id === key)?.price || 0) *
            receipt[key],
        0
      ),
    [receipt, data]
  );

  const onClickPurchase = () => {
    if (!totalPrice) {
      toast();
      return;
    }

    history.push({
      pathname: `${PRIVATE_ROUTE.DISCOUNT_COUPONS}/purchase`,
      state: {
        receipt: Object.fromEntries(
          Object.entries(receipt).filter(([_, count]) => count > 0)
        ),
      },
    });
  };

  return (
    <StyleProvider>
      <StackWrapper title="할인권">
        <VStack spacing={2}>
          {data?.coupons.map(({ _id, name, price }: ICouponKey) => (
            <LargeDiscountCouponCard
              _id={_id}
              key={_id}
              name={name}
              price={price}
              value={receipt[_id] || 0}
              setValue={(value) => setReceipt({ ...receipt, [_id]: value })}
              isEditable
            />
          ))}
        </VStack>
        <HStack justify="space-between" align="center" paddingTop={8}>
          <Heading fontSize="xl">결제 예정 금액</Heading>
          <Heading fontSize="2xl">{totalPrice.toLocaleString()}원</Heading>
        </HStack>
        <HStack paddingTop={6} className="buttons">
          <Button
            variant="outline"
            style={{
              padding: 0,
              height: '3.5rem',
            }}
            width="50%"
          >
            초기화
          </Button>
          <Button
            variant="brand"
            padding={7}
            width="50%"
            onClick={onClickPurchase}
          >
            구매하기
          </Button>
        </HStack>
      </StackWrapper>
    </StyleProvider>
  );
};

export default DiscountCouponKeys;

const StyleProvider = styled.div`
  div.scrollview {
    height: auto;
  }

  div.buttons {
    padding-bottom: 2.25rem;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 2.25rem);
    padding-bottom: calc(env(safe-area-inset-bottom) + 2.25rem);
  }
`;
