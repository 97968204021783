import React from 'react';
import { useLocation } from 'react-router-dom';
import { Center, Heading } from '@chakra-ui/react';
// import { HamburgerIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';

import { ReactComponent as SniLogo } from '../assets/logo.svg';

export interface IHeader {
  title?: string;
}

const Header: React.FC<IHeader> = ({ title }) => {
  const { pathname } = useLocation();
  const isMain = pathname === '/main';
  return (
    <Wrapper>
      {isMain ? (
        <Center paddingBottom={7} width="100%">
          <SniLogo style={{ width: '45%', height: 64 }} />
        </Center>
      ) : (
        <Heading size="lg" marginTop={2} marginBottom={7}>
          {title}
        </Heading>
      )}
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div`
  margin-top: 1.25rem;
  margin-top: calc(constant(safe-area-inset-bottom) + 1.25rem);
  margin-top: calc(env(safe-area-inset-bottom) + 1.25rem);
  /* position: relative; */
  /* cursor: pointer; */
  width: 100%;
  display: flex;
`;
