import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { SWRConfig } from 'swr';

import Router from './routes/Router';
import useAxios from './hooks/useAxios';
import theme from './theme';
import styled from 'styled-components';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './styles/index.css';

const App: React.FC = () => {
  const axios = useAxios();

  return (
    <SWRConfig
      value={{
        fetcher: (url, params) =>
          axios({
            method: 'GET',
            url,
            params,
          }).then((res) => res.data),
      }}
    >
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </SWRConfig>
  );
};

export default styled(App)`
  position: fixed;
  overflow: hidden;
`;
