import Main from './Main/Main';
import CalculateManage from './Calculate/Calculate';
import DiscountCouponManage from './DiscountCoupons/DiscountCoupons';
import SeasonTickets from './SeasonTickets/SeasonTickets';
import Reservations from './Reservations/Reservations';
import More from './More/More';

import VehicleManage from './Vehicles/Vehicles';
import VehicleRegister from './Vehicles/VehicleRegister';

import CalculateCoupons from './Calculate/CalculateCoupons';

import Purchase from './DiscountCoupons/Purchase';
import DiscountCouponKeys from './DiscountCoupons/DiscountCouponKeys';

export { default as Profile } from './Profile/Profile';
export { default as ProfilePassword } from './Profile/ProfilePassword';
export { default as Notices } from './Notices/Notices';
export { default as Feedbacks } from './Feedbacks/Feedbacks';
export { default as CreateFeedback } from './Feedbacks/CreateFeedback';
export { default as ViewFeedback } from './Feedbacks/ViewFeedback';
export { default as PaymentSuccess } from './Payment/PaymentSuccess';
export { default as PaymentCancel } from './Payment/PaymentCancel';

export const Tabs = {
  Main,
  Calculate: CalculateManage,
  DiscountCoupons: DiscountCouponManage,
  SeasonTickets,
  Reservations,
  More,
};

export const Vehicles = {
  Vehicles: VehicleManage,
  VehicleRegister,
};

export const Calculate = {
  CalculateCoupons,
};

export const DiscountCoupons = {
  Purchase,
  DiscountCouponKeys,
};
