import { useEffect, useState } from 'react';
import {
  loadTossPayments,
  TossPaymentsInstance,
} from '@tosspayments/payment-sdk';

export const useTossPayments = (
  clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY ||
    'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq'
) => {
  const [tossPayments, setTossPayments] = useState<TossPaymentsInstance>();

  useEffect(() => {
    if (clientKey) loadTossPayments(clientKey).then(setTossPayments);
  }, [clientKey]);

  return tossPayments;
};
