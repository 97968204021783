import React from 'react';
import { Button } from '@chakra-ui/react';

const AbsoluteButton = ({ containerStyle = {}, ...props }: any) => (
  <div
    style={{
      flex: 1,
      position: 'absolute',
      bottom: 100,
      left: '1.5rem',
      right: '1.5rem',
      ...containerStyle,
    }}
  >
    <Button variant="brand" size="natural" width="100%" {...props} />
  </div>
);

export default AbsoluteButton;
