import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import Header, { IHeader } from './Header';
import TabBar from './TabBar';

interface IServiceWrapper extends IHeader {
  fullHeight?: boolean;
}

const ServiceWrapper: React.FC<IServiceWrapper> = ({
  children,
  fullHeight,
  ...headerProps
}) => {
  return (
    <Container>
      <Box
        width="100%"
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          ...(fullHeight && { height: '100vh' }),
        }}
      >
        <ScrollView>
          <Header {...headerProps} />
          {children}
        </ScrollView>
        <div style={{ height: 80 }}></div>
        <TabBar />
      </Box>
    </Container>
  );
};

export default ServiceWrapper;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScrollView = styled.div`
  overflow-y: auto;
  padding: 0 1.5rem;
`;
