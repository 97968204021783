import { Client } from '@hapi/nes/lib/client';
import React, { useEffect, useRef, useContext } from 'react';
import { useRecoilState } from 'recoil';

import { accessTokenState } from '../recoil/auth';

const NesContext = React.createContext<Client | null>(null);

type Props = {
  children: React.ReactNode;
};

export const NesProvider = ({ children }: Props) => {
  const accessToken = useRecoilState(accessTokenState);
  const client = useRef<Client>(
    new Client(process.env.REACT_APP_WS_URL || '')
  );

  useEffect(() => {
    if (!accessToken) return;
    const nesClient = client.current;
    nesClient
      .connect({
        auth: { headers: { authorization: 'Bearer ' + accessToken } },
      })
      .catch((error) => console.log(error));
    return () => {
      if (!accessToken) return;
      if (nesClient.id) nesClient.disconnect();
    };
  }, [accessToken]);

  return (
    <NesContext.Provider value={client.current}>{children}</NesContext.Provider>
  );
};

export const useNes = () => useContext(NesContext);
