import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  VStack,
  Text,
  Heading,
  useToast,
  Select,
  HStack,
  Button,
} from '@chakra-ui/react';
import useSWR from 'swr';

import DiscountCouponCard from '../../components/DiscountCouponCard';
import StackWrapper from '../../components/StackWrapper';
import useAxios from '../../hooks/useAxios';
import { StringParam, useQueryParam } from 'use-query-params';

const CalculateCoupons = () => {
  const history = useHistory();
  const axios = useAxios();

  const toast = useToast({
    title: '할인권 적용에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });

  const [plateNumber] = useQueryParam('plateNumber', StringParam);
  const [vehicleAccess] = useQueryParam('vehicleAccess', StringParam);

  const { data: couponData, mutate: mutateCoupons } = useSWR(
    '/coupons'
  );
  const { data: vaData, mutate } = useSWR(
    `/settlements?plateNumber=${plateNumber}`
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [currentKey, setCurrentKey] = useState<string>('');

  const [previous, setPrevious] = useState<
    { _id: string; couponId: string; name: string }[]
  >([]);
  const [receipt, setReceipt] = useState<string[]>([]);

  const coupons = useMemo(() => {
    if (!couponData) return [];

    return Object.keys(couponData).map((key) => ({
      _id: key,
      ...couponData[key],
    }));
  }, [couponData]);

  // useEffect(() => {
  //   if (coupons && coupons.length) {
  //     setCurrentKey(coupons[0]._id);
  //   }
  // }, [coupons]);

  useEffect(() => {
    if (!vaData) return;
    setPrevious(
      vaData?.payment.discountCouponList.map(
        ({
          _id: couponId,
          discountCouponKey: { _id, name },
        }: {
          _id: string;
          discountCouponKey: { _id: string; name: string };
        }) => ({ _id, couponId, name })
      )
    );
  }, [vaData]);

  useEffect(() => {
    if (!vehicleAccess || !plateNumber) {
      toast({
        title: '오류가 발생했습니다',
        description: '잘못된 접근입니다.',
      });
      history.goBack();
    }
  }, [vehicleAccess, plateNumber, history, toast]);

  const onClickApply = async (currentKey: string) => {
    if (loading) return;
    if (!currentKey) {
      toast({
        description: '할인권을 선택해 주세요!',
      });
      return;
    }
    setReceipt([...receipt, currentKey]);
    setLoading(true);
    try {
      await axios.post('/coupons/use', {
        vehicleAccess,
        discountCouponKey: currentKey,
      });
      toast({
        status: 'success',
        title: '적용 완료',
        description: '할인권을 적용했습니다.',
      });
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message || '할인권 적용 중 오류가 발생했습니다.',
      });
    } finally {
      setCurrentKey('');
      setLoading(false);
      mutateCoupons();
      mutate();
    }
  };

  return (
    <StackWrapper title="할인권">
      <HStack marginBottom={6}>
        <Select
          size="lg"
          style={{
            fontSize: '0.95rem',
            fontWeight: 'bold',
            height: '3.5rem',
          }}
          value={currentKey}
          onChange={(e) => setCurrentKey(e.target.value)}
          placeholder="할인권을 선택하세요."
        >
          {coupons?.map(({ _id, name }) => (
            <option key={_id} value={_id}>
              {name}
            </option>
          ))}
        </Select>
        <Button
          variant="brand"
          padding={7}
          width="40%"
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: 56,
          }}
          onClick={() => onClickApply(currentKey)}
        >
          적용하기
        </Button>
      </HStack>
      <VStack marginBottom={6} spacing={6}>
        {previous?.map(({ _id: key, name, couponId }: any, index: number) => {
          return (
            <DiscountCouponCard
              _id={key}
              key={index}
              name={name || '이름 없음'}
              onClickRemove={async () => {
                try {
                  await axios.post('/coupons/remove', {
                    vehicleAccess,
                    couponId,
                  });
                  mutate();
                  toast({
                    status: 'success',
                    title: '취소 완료',
                    description: '할인권 사용을 취소했습니다.',
                  });
                } catch (err: any) {
                  toast({
                    description:
                      err?.response?.data?.message ||
                      '할인권 츼소 중 오류가 발생했습니다.',
                  });
                } finally {
                  mutateCoupons();
                  mutate();
                }
              }}
            />
          );
        })}
      </VStack>
      <VStack align="flex-start">
        <Heading size="md">할인권 사용 전 유의사항</Heading>
        <Text color="#98999B" fontSize="sm" paddingTop={4}>
          * 사용하신 할인권은 복원 또는 재발행이 불가합니다.
          <br />
          * 할인권의 잔여시간 환불은 불가합니다.
          <br />
          * 사용한 할인권 사용 시 구매 취소를 하실 수 없습니다.
          <br />
          * 할인권 시간 초과분은 별도 정산하셔야 합니다.
          <br />
          * 할인권은 차량 1대에 0장만 유효합니다.
          <br />* 할인권을 부정 사용시에는 정상요금으로 징수합니다.
        </Text>
      </VStack>
    </StackWrapper>
  );
};

export default CalculateCoupons;
