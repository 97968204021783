import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
  Select,
} from '@chakra-ui/react';

import StackWrapper from '../../components/StackWrapper';
import AbsoluteButton from '../../components/AbsoluteButton';
import { useForm } from 'react-hook-form';
import useAxios from '../../hooks/useAxios';
import Message from '../../components/Message';

const Feedbacks: React.FC = () => {
  const axios = useAxios();
  const history = useHistory();
  const { errors, register, handleSubmit } = useForm();

  const onSubmit = async (values: any) => {
    await axios.post('/feedbacks', values);
    history.goBack();
  };

  return (
    <StackWrapper title="불편신고 등록">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={6}>
          <FormControl>
            <FormLabel>제목</FormLabel>
            <Input
              name="title"
              ref={register({ required: '제목을 입력해주세요!' })}
            />
            {errors.title && <Message>{errors.title?.message}</Message>}
          </FormControl>
          <FormControl>
            <FormLabel>유형</FormLabel>
            <Select
              name="type"
              ref={register({ required: '유형을 선택해주세요!' })}
            >
              <option value="vehicle_number">차번인식</option>
              <option value="entry">입차</option>
              <option value="exit">출차</option>
              <option value="facility">주차설비</option>
              <option value="discount_coupon">할인권적용</option>
              <option value="question">문의</option>
              <option value="complain">불만</option>
              <option value="compliment">칭찬</option>
              <option value="proposal">건의</option>
              <option value="suggestion">제안</option>
              <option value="payment">결제문의</option>
              <option value="reservation">방문등록</option>
              <option value="accident">사고처리</option>
              <option value="register">회원가입</option>
              <option value="ticket_purchase">주차권구매</option>
              <option value="season_ticket_request">정기권신청</option>
              <option value="other">기타</option>
            </Select>
            {errors.type && <Message>{errors.type?.message}</Message>}
          </FormControl>
          <FormControl>
            <FormLabel>내용</FormLabel>
            <Textarea
              size="lg"
              name="content"
              ref={register({ required: '내용을 입력해주세요!' })}
            />
            {errors.content && <Message>{errors.content?.message}</Message>}
          </FormControl>
        </VStack>
        <AbsoluteButton type="submit">등록하기</AbsoluteButton>
      </form>
    </StackWrapper>
  );
};

export default Feedbacks;
