import React, { useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useSetRecoilState } from 'recoil';
import { Box, Center, Heading, Text, useToast } from '@chakra-ui/react';

import Dialog from '../../../components/Dialog';
import useAxios from '../../../hooks/useAxios';
import { userInfoState } from '../../../recoil/auth';
export interface IVehicle {
  plateNumber: string;
  modelName?: string;
}

interface IVehicleCard extends IVehicle {
  onClick?: () => void;
  mutate: () => void;
}

const VehicleCard: React.FC<IVehicleCard> = ({
  plateNumber,
  modelName,
  onClick,
  mutate,
}) => {
  const axios = useAxios();
  const toast = useToast({
    title: '차량 삭제에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const setUserInfo = useSetRecoilState(userInfoState);

  const onClickDelete = async () => {
    try {
      await axios.delete(`/vehicle/${plateNumber}`);
      toast({
        status: 'success',
        title: '삭제 완료',
        description: '차량을 삭제했습니다.',
      });
      axios.get('/users/me').then(({ data }) => setUserInfo(data));
      mutate();
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message || '삭제 중 오류가 발생했습니다.',
      });
      return;
    }
    setIsDialogOpen(false);
  };

  return (
    <Box
      background="white"
      padding={4}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.08)',
        borderRadius: '0.375rem',
      }}
      width="100%"
      cursor="pointer"
      onClick={onClick}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Heading fontSize="xl">{plateNumber}</Heading>
        {modelName && (
          <>
            <div
              style={{
                backgroundColor: '#999A9A',
                height: 12,
                width: 1,
                margin: '0 8px',
              }}
            />
            <Text fontSize="md" color="#999A9A">
              {modelName}
            </Text>
          </>
        )}
      </div>
      <Center height="100%" onClick={() => setIsDialogOpen(true)}>
        <FaRegTrashAlt color="#999A9A" />
      </Center>
      <Dialog
        title="차량 삭제"
        description={
          <Text>
            차량을 정말 삭제하시겠습니까? <br />
            <b>
              삭제 후 재등록 시 바로 로그아웃되고 관리자의 승인을 다시 받아야
              서비스 이용이 가능합니다.
            </b>
          </Text>
        }
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={onClickDelete}
      />
    </Box>
  );
};

export default VehicleCard;
