import { differenceInMinutes, format as fnsFormat } from 'date-fns';

export const format = (date?: string | null, more = true) => {
  if (!date) return '';
  return fnsFormat(new Date(date), 'yyyy-MM-dd' + (more ? ' HH:mm:ss' : ''));
};

export const formatDistanceToNow = (date: string) => {
  const minutes = differenceInMinutes(new Date(), new Date(date));
  const hours = Math.floor(minutes / 60);
  if (hours > 0) return `${hours}시간 ${minutes - hours * 60}분`;
  else return `${minutes}분`;
};

export const formatDistanceToExit = (entryDate: string, exitDate: string) => {
  const minutes = differenceInMinutes(new Date(exitDate), new Date(entryDate));
  const hours = Math.floor(minutes / 60);
  if (hours > 0) return `${hours}시간 ${minutes - hours * 60}분`;
  else return `${minutes}분`;
};
