import React, { useState } from 'react';
import styled, { css } from 'styled-components';

interface IBadge {
  status: any;
}

interface IHistoryCard {
  status: any;
  statusText: any;
  vehicle: string;
  amount?: number | string;
  createdAt: string;
  onCancel?: any;
  children?: React.ReactNode;
}

const HistoryCard: React.FC<IHistoryCard> = ({
  vehicle,
  status,
  statusText,
  amount,
  createdAt,
  onCancel,
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <ListBox>
      <Container>
        <ListContent>
          <Label>{vehicle}</Label>
          <ListContentValue>
            {amount &&
              (typeof amount === 'number'
                ? `${amount.toLocaleString()}원`
                : amount)}
            {statusText?.[status] && (
              <Badge status={status}>{statusText[status]}</Badge>
            )}
          </ListContentValue>
        </ListContent>
        <ListContent>
          {onCancel && (
            <ListContentMore onClick={onCancel}>취소</ListContentMore>
          )}
          <CreatedAt>{createdAt}</CreatedAt>
        </ListContent>
      </Container>
      {!!children && (
        <ExpandContainer>
          <ListContentMore onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? '정보 숨기기' : '자세히 보기'} {'>'}
          </ListContentMore>
          {isOpen && children}
        </ExpandContainer>
      )}
    </ListBox>
  );
};

export default HistoryCard;

const Label = styled.span`
  color: #a1a6ad;
  font-weight: 400;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(247, 246, 250);
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &:last-child {
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media screen and (max-width: 500px) {
    &:last-child {
      align-items: unset;
      justify-content: space-between;
    }
  }
`;

const ListContentValue = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
`;

const ListContentMore = styled.span`
  color: rgb(31, 40, 70);
  text-decoration: underline;
  cursor: pointer;
`;

const Badge = styled.span<IBadge>`
  padding: 5px 8px;
  border-radius: 50rem;
  margin-left: 8px;
  font-size: 0.9rem;
  font-weight: normal;

  ${({ status }) =>
    (status === '01' || status === 'done') &&
    css`
      background-color: rgb(221, 222, 232);
      color: rgb(31, 40, 70);
    `}

  ${({ status }) =>
    (status === '02' || status === 'cancel') &&
    css`
      background-color: rgb(237, 228, 238);
      color: rgb(150, 53, 46);
    `}

  ${({ status }) =>
    status === '03' &&
    css`
      background-color: rgb(248, 241, 229);
      color: rgb(218, 177, 112);
    `}
`;

const CreatedAt = styled.span`
  @media screen and (max-width: 500px) {
    margin-top: 8px;
    font-size: 0.9rem;
  }
`;

const ExpandContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-top: 8px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);

  & > span {
    margin-bottom: 8px;
  }
`;
