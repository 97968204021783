import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DayRange } from 'react-modern-calendar-datepicker';
import {
  Input,
  VStack,
  FormControl,
  FormLabel as Label,
  Text,
  Select,
  Button,
  useToast,
  Alert,
  AlertIcon,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import useSWR from 'swr';
import { PHONE_REGEX, VEHICLE_REGEX } from '../../utils/regex';

import StackWrapper from '../../components/StackWrapper';
import CalendarRow from '../../components/CalendarRow';
import useAxios from '../../hooks/useAxios';
import { phoneNormalizer } from '../../utils/normalizers';
import { formStyle } from '../../theme';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../../recoil/auth';
import { startOfDay, endOfDay } from 'date-fns';

const ReservationSubmit: React.FC = () => {
  const history = useHistory();
  const location = useLocation<{
    vehicle: string;
    discountCouponKey: string | undefined;
    phone: string | undefined;
  }>();
  const userInfo = useRecoilValue(userInfoState);
  const isYongsanCustom = useMemo(
    () => userInfo?.site._id === 'LG_UPLUS_YONGSAN',
    [userInfo?.site._id]
  );
  const axios = useAxios();
  const toast = useToast({
    title: '방문자 사전예약에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [purposeClass, setPurposeClass] = useState<string>('업무미팅');
  const [selectedDay, setSelectedDay] = useState<DayRange>({
    from: null,
    to: null,
  });

  const { data: couponData } = useSWR('/coupons');
  const { errors, register, handleSubmit, watch } = useForm({
    mode: 'onChange',
  });
  const { vehicleModel } = watch();

  const couponOptions = useMemo<{ value: string; label: string }[]>(
    () =>
      couponData
        ? Object.keys(couponData).map((key) => ({
            value: key,
            label: couponData?.[key]?.name || '이름 없음',
          }))
        : [],
    [couponData]
  );

  const onSubmit = async ({
    name,
    phone,
    company,
    department,
    position,
    discountCouponKey,
    visitLimit,
    purpose,
    vehicleModel,
    otherVehicleModel,
    ...values
  }: any) => {
    if (loading) return;
    const { from, to } = selectedDay;
    if (!from || !to) {
      toast({ title: '잠깐!', description: '방문 일정을 입력해 주세요!' });
      setLoading(false);
      return;
    }

    if (discountCouponKey) {
      // TODO: 할인권 남은 개수 확인
    }

    const startAt = startOfDay(new Date(from.year, from.month - 1, from.day))
    const endAt = endOfDay(new Date(to.year, to.month - 1, to.day))

    const payload = {
      ...values,
      user: {
        name,
        phone,
        company: company || undefined,
        department: department || undefined,
        position: position || undefined,
        vehicleModel:
          vehicleModel === '기타' ? otherVehicleModel : vehicleModel,
      },
      discountCouponKey: discountCouponKey || undefined,
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
      visitLimit,
      remarks: '앱 방문등록',
      purpose: purpose || purposeClass,
    };
    try {
      setLoading(true);
      await axios.post('/reservations', payload);
      toast({
        status: 'success',
        title: '방문 예약 완료',
        description: '방문 예약이 완료되었습니다.',
      });
      history.goBack();
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message ||
          '방문예약 등록 중에 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
      });
      setLoading(false);
    }
  };

  return (
    <StackWrapper title={isYongsanCustom ? '주차 할인' : '방문자 사전예약'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={6}>
          <FormControl>
            <FormLabel>차량 번호</FormLabel>
            <Input
              style={formStyle}
              name="vehicle"
              ref={register({
                required: '차량 번호를 입력해 주세요!',
                pattern: {
                  value: VEHICLE_REGEX,
                  message: '올바른 차량번호를 입력해주세요.',
                },
              })}
              placeholder="예) 12가1234, 123가4567"
              defaultValue={location?.state?.vehicle}
            />
            {errors.vehicle && <Message>{errors.vehicle?.message}</Message>}
          </FormControl>
          <FormControl>
            <FormLabel>전화번호</FormLabel>
            <Input
              style={formStyle}
              name="phone"
              onChange={(e) => {
                e.target.value = phoneNormalizer(e.target.value);
              }}
              ref={register({
                required: '전화번호를 입력해 주세요!',
                pattern: {
                  value: PHONE_REGEX,
                  message: '잘못된 형식의 전화번호입니다.',
                },
              })}
              defaultValue={location?.state?.phone}
            />
            {errors.phone && <Message>{errors.phone?.message}</Message>}
          </FormControl>
          <FormControl>
            <FormLabel>할인권 등록</FormLabel>
            <Select
              style={formStyle}
              name="discountCouponKey"
              ref={register}
              defaultValue={location?.state?.discountCouponKey}
            >
              <option value={''}>선택안함</option>
              {couponOptions.map(({ label, value }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Select>
            {errors.discountCouponKey && (
              <Message>{errors.discountCouponKey?.message}</Message>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>차량 종류</FormLabel>
            <Select style={formStyle} name="vehicleModel" ref={register}>
              <option value="">선택안함</option>
              <option value="경차">경차</option>
              <option value="세단">세단</option>
              <option value="SUV">SUV</option>
              <option value="버스">버스</option>
              <option value="트럭">트럭</option>
              <option value="탑차">탑차</option>
              <option value="기타">기타</option>
            </Select>
            {errors.vehicleModel && (
              <Message>{errors.vehicleModel?.message}</Message>
            )}
          </FormControl>
          {vehicleModel === '기타' && (
            <FormControl>
              <Input
                style={formStyle}
                name="otherVehicleModel"
                placeholder="차량 종류 직접 입력"
                ref={register({
                  required: '차량 종류를 직접 입력해 주세요!',
                })}
              />
              {errors.otherVehicleModel && (
                <Message>{errors.otherVehicleModel?.message}</Message>
              )}
            </FormControl>
          )}
          <FormControl>
            <FormLabel>이름</FormLabel>
            <Input
              style={formStyle}
              name="name"
              ref={register({ required: '이름을 입력해 주세요!' })}
            />
            <FormLabel>*실명을 입력해주세요</FormLabel>
            {errors.name && <Message>{errors.name?.message}</Message>}
          </FormControl>
          {/* <FormControl>
            <FormLabel>회사</FormLabel>
            <Input style={formStyle} />
          </FormControl> */}
          <FormControl>
            <FormLabel>방문일정</FormLabel>
            <CalendarRow value={selectedDay} onChange={setSelectedDay} />
          </FormControl>
          <FormControl>
            <FormLabel>방문횟수 제한</FormLabel>
            <Input
              style={formStyle}
              name="visitLimit"
              ref={register}
              defaultValue={1}
            />
            {errors.visitLimit && (
              <Message>{errors.visitLimit?.message}</Message>
            )}
          </FormControl>
          <Alert status="warning">
            <AlertIcon />
            방문횟수 제한만큼 할인권이 차감됩니다. (ex: 2회로 제한 시 2개 차감)
          </Alert>
          <FormControl>
            <FormLabel>방문목적</FormLabel>
            <StyledRadioGroup
              onChange={(value: string) => setPurposeClass(value)}
            >
              <Radio value="업무미팅">업무미팅</Radio>
              <Radio value="공사">공사</Radio>
              <Radio value="작업">작업</Radio>
              <Radio value="택배">택배</Radio>
              <Radio value="기타">기타</Radio>
            </StyledRadioGroup>
            {purposeClass === '기타' && (
              <Input
                style={formStyle}
                name="purpose"
                ref={register}
                placeholder="방문목적 직접 입력"
              />
            )}
          </FormControl>
          <FormControl>
            <FormLabel>회사</FormLabel>
            <Input style={formStyle} name="company" ref={register} />
          </FormControl>
          <FormControl>
            <FormLabel>부서</FormLabel>
            <Input style={formStyle} name="department" ref={register} />
          </FormControl>
          <FormControl>
            <FormLabel>직책</FormLabel>
            <Input style={formStyle} name="position" ref={register} />
          </FormControl>

          {/* <FormControl>
            <FormLabel>후불결제 여부</FormLabel>
            <Switch />
          </FormControl> */}

          {/* <FormControl>
            <FormLabel>입차알림 설정</FormLabel>
            <Select size="lg" style={formStyle} name="plateNumber">
              <option>예</option>
            </Select>
          </FormControl> */}
        </VStack>
        <Button
          variant="brand"
          width="100%"
          type="submit"
          marginTop="1rem"
          size="natural"
          isLoading={loading}
        >
          등록하기
        </Button>
      </form>
    </StackWrapper>
  );
};

export default ReservationSubmit;

const FormLabel: React.FC<any> = ({ children, ...props }) => (
  <Label
    color="#828687"
    fontSize="0.9rem"
    marginTop={1}
    marginBottom={1}
    {...props}
  >
    {children}
  </Label>
);

const Message = (props: any) => (
  <Text fontSize="xs" color="brand" marginTop={1} {...props} />
);

const StyledRadioGroup = styled(RadioGroup)`
  margin: 16px 0;

  & > label.chakra-radio {
    margin-right: 1.8rem;
  }
`;
