import React, { useMemo, useState } from 'react';
import { Accordion, Text, Center, Spinner } from '@chakra-ui/react';
import useSWR from 'swr';

import PDFPage from '../../components/PDFPage';
import StackWrapper from '../../components/StackWrapper';
import NoticeItem, { INoticeItem } from './NoticeItem';

import { useRecoilValue } from 'recoil';
import { userInfoState } from '../../recoil/auth';

const Notices: React.FC = () => {
  const { data } = useSWR('/notices');
  const userInfo = useRecoilValue(userInfoState);

  const isThemepolisCustom = useMemo(
    () => userInfo?.site._id === 'SBI_EYEVACS_0009',
    [userInfo?.site._id]
  );

  const [noticeOpen, setNoticeOpen] = useState(false);

  return (
    <StackWrapper title="공지사항">
      {isThemepolisCustom && (
        <Accordion>
          <NoticeItem
            title="주차장 이용안내 (필독)"
            content=""
            createdAt=""
            files={[]}
            onClick={() => setNoticeOpen(true)}
          />
          <PDFPage
            open={noticeOpen}
            onClose={() => setNoticeOpen(false)}
            href="https://eyevacs.github.io/data/pdf/themepolis_notice.pdf"
          />
        </Accordion>
      )}
      {!!data?.docs ? (
        data.docs.length ? (
          <Accordion allowToggle>
            {data?.docs?.map((notice: INoticeItem, noticeIndex: number) => (
              <NoticeItem key={noticeIndex} {...notice} />
            ))}
          </Accordion>
        ) : (
          <Text color="rgba(0, 0, 0, 0.95)" align="center" fontSize="md">
            등록된 공지사항이 없습니다.
          </Text>
        )
      ) : (
        <Center minHeight={500}>
          <Spinner />
        </Center>
      )}
    </StackWrapper>
  );
};

export default Notices;
