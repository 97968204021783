import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { accessTokenState } from '../recoil/auth';

interface IPrivateRoute extends RouteProps {
  component?: React.ComponentClass<any>;
  children?: React.ReactNode;
}

const PrivateRoute = ({ component, children, ...rest }: IPrivateRoute) => {
  const token = useRecoilValue(accessTokenState);

  return (
    <Route
      {...rest}
      render={(props) => (token ? component : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
