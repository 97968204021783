import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';

import StackWrapper from '../../components/StackWrapper';
import AbsoluteButton from '../../components/AbsoluteButton';
import Message from '../../components/Message';

import useAxios from '../../hooks/useAxios';

const Profile: React.FC = () => {
  const axios = useAxios();
  const toast = useToast({
    title: '비밀번호 변경에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const { errors, register, getValues, handleSubmit, reset } = useForm();

  const onSubmit = async ({ password, newPassword }: any) => {
    if (loading) return;
    setLoading(true);
    try {
      await axios.post('/password', { password, newPassword });
      toast({
        status: 'success',
        title: '성공',
        description: '비밀번호 변경이 완료되었습니다.',
      });
      reset({ password: '', newPassword: '', reNewPassword: '' });
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message ||
          '비밀번호 변경 중 오류가 발생했습니다.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StackWrapper title="비밀번호 변경">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={6}>
          <FormControl>
            <FormLabel>현재 비밀번호</FormLabel>
            <Input
              name="password"
              type="password"
              ref={register({
                required: '현재 사용하고 있는 비밀번호를 입력하세요!',
              })}
              placeholder="현재 사용하고 있는 비밀번호를 입력해주세요."
            />
            {errors.password && <Message>{errors.password?.message}</Message>}
          </FormControl>
          <FormControl>
            <FormLabel>새로운 비밀번호</FormLabel>
            <Input
              name="newPassword"
              type="password"
              placeholder="새로운 비밀번호를 입력해 주세요."
              ref={register({
                required: '새로운 비밀번호를 입력하세요!',
                pattern: {
                  value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i,
                  message:
                    '숫자와 알파벳, 특수문자가 모두 포함된 8자리 이상의 비밀번호를 입력해주세요.',
                },
                // validate: (value) =>
                //   !value ||
                //   !value.includes(getValues('email').split('@')?.[0]) ||
                //   '이메일의 아이디가 포함되어서는 안됩니다.',
              })}
            />
            {errors.newPassword && (
              <Message>{errors.newPassword?.message}</Message>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>새로운 비밀번호 확인</FormLabel>
            <Input
              name="reNewPassword"
              type="password"
              ref={register({
                required: '새로운 비밀번호를 다시 한번 입력하세요!',
                validate: (value) =>
                  !value ||
                  getValues('newPassword') === value ||
                  '비밀번호가 일치하지 않습니다',
              })}
              placeholder="새로운 비밀번호를 확인해 주세요."
            />
            {errors.reNewPassword && (
              <Message>{errors.reNewPassword?.message}</Message>
            )}
          </FormControl>
        </VStack>
        <AbsoluteButton
          containerStyle={{ position: 'unset', marginTop: 32 }}
          type="submit"
          isLoading={loading}
        >
          비밀번호 변경하기
        </AbsoluteButton>
      </form>
    </StackWrapper>
  );
};

export default Profile;
