import React, { useMemo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Text, HStack, VStack, useToast } from '@chakra-ui/react';
import useSWR from 'swr';

import AbsoluteButton from '../../components/AbsoluteButton';
import StackWrapper from '../../components/StackWrapper';
import LargeDiscountCouponCard, {
  ICouponKey,
} from './components/LargeDiscountCouponCard';
import useAxios from '../../hooks/useAxios';
import { useTossPayments } from '../../hooks/useTossPayments';

const PurchaseRow: React.FC = (props: any) => (
  <HStack
    justify="space-between"
    align="baseline"
    py={4}
    style={{
      borderBottom: `1px solid #e2e8f0`,
    }}
    {...props}
  />
);

const Purchase = () => {
  const axios = useAxios();
  const tossPayments = useTossPayments();

  const toast = useToast({
    title: '할인권 구매에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });

  const { data } = useSWR('/coupon-keys');
  const { state: { receipt } } = useLocation<{ receipt: { [key: string]: number } }>();

  const [loading, setLoading] = useState(false);

  const totalPrice = useMemo<number>(
    () =>
      Object.keys(receipt).reduce(
        (total, key) =>
          total +
          (data?.coupons?.find((v: ICouponKey) => v._id === key)?.price || 0) *
          receipt[key],
        0
      ),
    [receipt, data]
  );

  const onClickPurchase = useCallback(
    async (method: '카드' | '토스페이') => {
      if (loading || !tossPayments) return;
      setLoading(true);

      try {
        const res = await axios.post(
          '/coupon-keys',
          Object.keys(receipt).map((key) => ({
            discountCouponKey: key,
            quantity: receipt[key],
          }))
        );

        await tossPayments.requestPayment(method, res.data);
      } catch (err: any) {
        if (err.code !== 'USER_CANCEL') {
          toast({
            description:
              err?.response?.data?.message ||
              '할인권 구매 중 오류가 발생했습니다.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [axios, loading, receipt, toast, tossPayments]
  );

  return (
    <StackWrapper title="할인권 구매">
      <PurchaseRow>
        <Text fontSize="lg">할인권 정보</Text>
      </PurchaseRow>
      <VStack spacing={4} py={6}>
        {Object.keys(receipt).map((key) => {
          const coupon = data?.coupons?.find((v: ICouponKey) => v._id === key);
          return (
            <LargeDiscountCouponCard
              _id={key}
              key={key}
              name={coupon?.name}
              price={coupon?.price}
              value={receipt[key]}
              isPreview
            />
          );
        })}
      </VStack>
      <PurchaseRow>
        <Text fontSize="lg">최종 결제 금액</Text>
        <Text fontSize="lg">{totalPrice.toLocaleString()}원</Text>
      </PurchaseRow>
      {!!totalPrice && (
        <>
          <AbsoluteButton onClick={() => onClickPurchase('카드')} style={{ bottom: 48 + 8 }}>
            카드 결제하기
          </AbsoluteButton>
          <AbsoluteButton onClick={() => onClickPurchase('토스페이')}>
            토스페이 결제하기
          </AbsoluteButton>
        </>
      )}
    </StackWrapper>
  );
};

export default Purchase;
