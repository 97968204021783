import React from 'react';
import {
  HStack,
  VStack,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { AiOutlineCar } from 'react-icons/ai';

interface IVehicleInput {
  vehicle?: string;
}

const VehicleInput: React.FC<IVehicleInput> = ({ vehicle }) => {
  if (!vehicle)
    return (
      <Alert status="warning" marginBottom={8}>
        <AlertIcon />
        {'더보기 > 차량 등록에서 차량을 먼저 등록해주세요.'}
      </Alert>
    );
  return (
    <ShadowBox>
      <HStack justify="flex-start" width="100%" align="center">
        <IconWrap>
          <AiOutlineCar size="2rem" />
        </IconWrap>
        <VStack align="flex-start" paddingLeft={1}>
          <Text color="#8C9597">차량번호</Text>
          <Text fontSize="lg" fontWeight="bold" style={{ marginTop: 0 }}>
            {vehicle}
          </Text>
        </VStack>
      </HStack>
    </ShadowBox>
  );
};

export default VehicleInput;

const ShadowBox = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.95rem;
  font-weight: bold;
  height: 6rem;
  padding: 1rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  border-radius: 1rem;
`;

const IconWrap = styled.div`
  background-color: #f3f9f9;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
