import { Text, TextProps } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../recoil/auth';

const Message = Fragment

const BusinessFooter: FC<TextProps> = (props) => {
  const userInfo = useRecoilValue(userInfoState);

  return (
    <Text fontSize="xs" align="center" {...props}>
      <Message>
        <strong>상호:</strong>{' '}
        {userInfo ? userInfo.site.businessName : '㈜실리콘브릿지'}
      </Message>
      {' | '}
      <Message>
        <strong>대표:</strong> {userInfo ? userInfo.site.owner.name : '김태호'}
      </Message>
      {' | '}
      <Message>
        <strong>사업자등록번호:</strong>{' '}
        {userInfo ? userInfo.site.businessNumber : '395-87-00215'}
      </Message>
      {' | '}
      <Message>
        <strong>주소:</strong>{' '}
        {userInfo
          ? `${userInfo.site.address1} ${userInfo.site.address2 || ''}`
          : '경기 성남시 중원구 갈마치로288번길 14, 731호 (상대원동, 성남 SK V1 tower)'}
      </Message>
      {' | '}
      <Message>
        <strong>전화:</strong> {userInfo ? userInfo.site.tel : '1670-8891'}
      </Message>
      {!userInfo && (
        <>
          {' | '}
          <Message>
            <strong>통신판매업신고:</strong> 제2016-성남분당-0136호
          </Message>
        </>
      )}
    </Text>
  );
};

export default BusinessFooter;
