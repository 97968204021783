import React from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import {
  Box,
  HStack,
  VStack,
  Text,
  Input,
  Heading,
  Divider,
} from '@chakra-ui/react';
import styled from 'styled-components';

export interface ICouponKey {
  _id: string;
  name: string;
  price?: number;
}

interface ILargeDiscountCouponCard extends ICouponKey {
  isEditable?: boolean;
  isPreview?: boolean;
  value?: number;
  setValue?: (value: number) => void;
}

const LargeDiscountCouponCard: React.FC<ILargeDiscountCouponCard> = ({
  _id,
  name,
  price,
  isEditable,
  isPreview,
  value,
  setValue,
}) => {
  return (
    <Box
      style={{
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.08)',
        borderRadius: '0.375rem',
      }}
      width="100%"
      padding={6}
    >
      <Heading fontSize="lg" paddingBottom={2}>
        {name}
      </Heading>
      {isPreview ? (
        <Text lineHeight={2}>
          수량: {value?.toLocaleString()}개<br />
          합계: {(price || 0) * (value || 0)}원
        </Text>
      ) : (
        <>
          <Divider my={2} marginBottom={4} />
          {price && (
            <>
              <VStack align="flex-start">
                <HStack align="center" justify="space-between" width="100%">
                  <Text color="#A8A8A8" fontSize="xs">
                    가격
                  </Text>
                  <Heading color="#1a202c" fontSize="lg">
                    {price?.toLocaleString()}원
                  </Heading>
                </HStack>
                {/* <HStack align="center" justify="space-between" width="100%">
          <Text color="#A8A8A8" fontSize="xs">
            유효기간
          </Text>
          <Text color="rgba(0, 0, 0, 0.85)" fontSize="lg">
            ~2020.01.01까지(30일)
          </Text>
        </HStack> */}
              </VStack>
              <Divider my={4} />
            </>
          )}
          <HStack align="center" justify="space-between">
            <Text color="#A8A8A8" fontSize="xs">
              수량
            </Text>
            {isEditable ? (
              <HStack align="center">
                <IconButton
                  onClick={
                    value! - 1 >= 0 ? () => setValue!(value! - 1) : undefined
                  }
                >
                  <AiOutlineMinus size="1.05rem" />
                </IconButton>
                <Input
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.85)',
                    borderRadius: 8,
                    width: 80,
                    textAlign: 'center',
                  }}
                  size="sm"
                  type="number"
                  value={value}
                  onChange={(e) => setValue!(Number(e.target.value))}
                />
                <IconButton onClick={() => setValue!(value! + 1)}>
                  <AiOutlinePlus size="1.05rem" />
                </IconButton>
              </HStack>
            ) : (
              <Text color="rgba(0, 0, 0, 0.85)" fontSize="lg">
                {value?.toLocaleString()}개
              </Text>
            )}
          </HStack>
        </>
      )}
    </Box>
  );
};

export default LargeDiscountCouponCard;

const IconButton = styled.button`
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.85);
  border-radius: 50%;
`;
