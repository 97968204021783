import {
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Center,
  useToast,
  // AlertDialogOverlay,
  // AlertDialogBody,
  // AlertDialogFooter,
  // Button,
  Alert,
  AlertIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import ServiceWrapper from '../../components/ServiceWrapper';
import LargeDiscountCouponCard from './components/LargeDiscountCouponCard';
import AbsoluteButton from '../../components/AbsoluteButton';
import HistoryCard from '../../components/HistoryCard';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';
import { format } from '../../utils/date';
// import VehicleInput from '../../components/VehicleInput';
import useAxios from '../../hooks/useAxios';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../../recoil/auth';

const DiscountCoupons: React.FC = () => {
  const history = useHistory();
  const axios = useAxios();
  const userInfo = useRecoilValue(userInfoState);
  const isYongsanCustom = useMemo(
    () => userInfo?.site._id === 'LG_UPLUS_YONGSAN',
    [userInfo?.site._id]
  );

  const {
    data,
    isValidating,
    mutate: mutateCoupons,
  } = useSWR('/coupons');

  const toast = useToast({ duration: 3000, isClosable: true, position: 'top' });

  const coupons = useMemo(() => {
    if (!data) return [];

    return Object.keys(data).map((key) => ({
      _id: key,
      ...data[key],
    }));
  }, [data]);

  const { data: applyData, mutate: mutateApplyData } =
    useSWR(`/coupons/histories`);
  const { data: chargeData } = useSWR('/coupon-keys/histories');
  const { data: paymentData, mutate: mutatePayments } =
    useSWR('/coupons/payments');

  return (
    <ServiceWrapper title="할인권">
      <Tabs isFitted colorScheme="brand">
        <TabList mb="1em">
          <Tab px={0}>보유내역</Tab>
          {!isYongsanCustom && <Tab px={0}>결제내역</Tab>}
          <Tab px={0}>적용내역</Tab>
          <Tab px={0}>충전내역</Tab>
        </TabList>
        <TabPanels>
          {/* 보유내역 */}
          <TabPanel>
            <LargeButton
              onClick={() =>
                history.push(`${PRIVATE_ROUTE.DISCOUNT_COUPONS}/coupon-keys`)
              }
            >
              할인권 구매하기
            </LargeButton>
            <VStack spacing={2}>
              {!data && isValidating && (
                <Center>
                  <Spinner />
                </Center>
              )}
              {coupons.map(({ _id, name, quantity }) => (
                <LargeDiscountCouponCard
                  _id={_id}
                  key={_id}
                  name={name}
                  value={quantity}
                />
              ))}
            </VStack>
          </TabPanel>

          {/* 결제내역 */}
          {!isYongsanCustom && (
            <TabPanel>
              {(!paymentData || !paymentData.docs.length) && (
                <Alert status="warning" marginBottom={8}>
                  <AlertIcon />
                  내역이 없습니다.
                </Alert>
              )}
              <VStack width="100%" spacing={4}>
                {paymentData?.docs.map(
                  (paymentHistory: any, paymentHistoryIndex: number) => (
                    <HistoryCard
                      key={paymentHistoryIndex}
                      status={paymentHistory.paymentType}
                      statusText={{ done: '결제성공', cancel: '결제취소' }}
                      vehicle={`${
                        paymentHistory.coupons?.[0]?.discountCouponKey?.name ||
                        ''
                      }${
                        paymentHistory.coupons.length > 1
                          ? ` 외 ${paymentHistory.coupons.length - 1} 종`
                          : ``
                      }`}
                      amount={`${Number(
                        paymentHistory?.price || 0
                      ).toLocaleString()}원`}
                      createdAt={format(paymentHistory?.createdAt)}
                      onCancel={
                        paymentHistory.paymentType === 'done' &&
                        (async () => {
                          try {
                            await axios.post(
                              `/coupons/payments/${paymentHistory._id}/cancel`
                            );
                            toast({
                              title: '성공!',
                              status: 'success',
                              description: '할인권 결제 취소를 성공했습니다.',
                            });
                          } catch (err: any) {
                            toast({
                              title: '실패',
                              status: 'error',
                              description:
                                err?.response?.data?.message ||
                                '할인권 결제 취소 중 오류가 발생했습니다.',
                            });
                          } finally {
                            mutateCoupons();
                            mutatePayments();
                          }
                        })
                      }
                    >
                      <Table
                        variant="simple"
                        size="sm"
                        style={{ backgroundColor: 'rgba(255,255,255,0.45)' }}
                      >
                        <Thead>
                          <Tr>
                            <Th>이름</Th>
                            <Th>가격</Th>
                            <Th isNumeric>수량</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {paymentHistory?.coupons?.map?.(
                            (coupon: any, couponIndex: number) => (
                              <Tr key={couponIndex}>
                                <Td>{coupon.discountCouponKey.name}</Td>
                                <Td>
                                  {`${coupon.discountCouponKey.price.toLocaleString()}원`}
                                </Td>
                                <Td isNumeric>
                                  {`${coupon.quantity.toLocaleString()}개`}
                                </Td>
                              </Tr>
                            )
                          )}
                        </Tbody>
                      </Table>
                    </HistoryCard>
                  )
                )}
              </VStack>
            </TabPanel>
          )}

          {/* 적용내역 */}
          <TabPanel>
            {(!applyData || !applyData.couponApplyHistories.length) && (
              <Alert status="warning" marginBottom={8}>
                <AlertIcon />
                내역이 없습니다.
              </Alert>
            )}
            <VStack width="100%" spacing={4}>
              {applyData?.couponApplyHistories.map(
                (applyHistory: any, applyHistoryIndex: number) => (
                  <HistoryCard
                    key={applyHistoryIndex}
                    status={
                      applyHistory.discountCouponHistoryClssCode === 'unmark'
                        ? '02'
                        : applyHistory.discountCouponHistoryClssCode === 'mark'
                        ? '01'
                        : '03'
                    }
                    statusText={{
                      '01': '할인권 사용',
                      '02': '할인권 취소',
                      '03': '기타',
                    }}
                    vehicle={`${
                      applyHistory?.vehicle ||
                      applyHistory?.vehicleAccess?.vehicle ||
                      applyHistory?.discountCoupon?.uses?.vehicle ||
                      ''
                    }`}
                    amount={`${
                      applyHistory?.discountCoupon?.discountCouponKey?.name ||
                      '이름 없음'
                    }`}
                    createdAt={format(applyHistory?.usedAt)}
                    onCancel={
                      !!applyHistory &&
                      !applyHistory.isCancelled &&
                      applyHistory.discountCouponHistoryClssCode === 'mark' &&
                      (async () => {
                        try {
                          await axios.post(
                            `/coupons/histories/${applyHistory._id}/cancel`
                          );
                          toast({
                            title: '성공!',
                            status: 'success',
                            description: '할인권 적용 취소를 성공했습니다.',
                          });
                        } catch (err: any) {
                          toast({
                            title: '실패',
                            status: 'error',
                            description:
                              err?.response?.data?.message ||
                              '할인권 적용 취소 중 오류가 발생했습니다.',
                          });
                        } finally {
                          mutateApplyData();
                          mutateCoupons();
                          mutatePayments();
                        }
                      })
                    }
                  />
                )
              )}
            </VStack>
          </TabPanel>

          {/* 충전내역 */}
          <TabPanel>
            {(!chargeData || !chargeData.couponChargeHistories.length) && (
              <Alert status="warning" marginBottom={8}>
                <AlertIcon />
                내역이 없습니다.
              </Alert>
            )}
            <VStack width="100%" spacing={4}>
              {chargeData?.couponChargeHistories.map(
                (chargeHistory: any, chargeHistoryIndex: number) => (
                  <HistoryCard
                    key={chargeHistoryIndex}
                    status="01"
                    statusText={{ '01': '', '02': '', '03': '' }}
                    vehicle={`${chargeHistory?.issuer?.name || ''}`}
                    amount={`${
                      chargeHistory?.discountCouponKey?.name || '이름 없음'
                    } / ${(chargeHistory?.quantity || 0).toLocaleString()}개`}
                    createdAt={format(chargeHistory?.createdAt)}
                  />
                )
              )}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ServiceWrapper>
  );
};

export default DiscountCoupons;

const LargeButton = (props: any) => (
  <AbsoluteButton
    containerStyle={{ position: 'unset', marginBottom: 24 }}
    {...props}
  />
);
