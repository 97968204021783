import React, { useState } from 'react';
import { Center, Flex, HStack, Tag, useToast } from '@chakra-ui/react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FaRegTrashAlt } from 'react-icons/fa';
import useAxios from '../hooks/useAxios';
import Dialog from './Dialog';
import { PRIVATE_ROUTE } from '../routes/routes.constants';
import { useHistory } from 'react-router-dom';

type StatusType = '01' | '02' | '03';
export interface IReservation {
  _id: string;
  vehicle: string;
  user: {
    name: string;
  };
  startAt: string;
  endAt: string;
  status: StatusType;
}

interface IReservationCard extends IReservation {
  mutate: () => void;
}

const getStatusByData = (status: StatusType) => {
  switch (status) {
    case '01':
      return '사용중';
    case '02':
      return '사용종료';
    case '03':
      return '삭제대기';
  }
};

const ReservationCard: React.FC<IReservationCard> = ({
  _id,
  vehicle,
  user,
  startAt,
  endAt,
  mutate,
  status,
}) => {
  const history = useHistory();
  const axios = useAxios();
  const toast = useToast({
    title: '방문예약 삭제에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onClickDelete = async () => {
    try {
      await axios.delete(`/reservations/${_id}`);
      toast({
        status: 'success',
        title: '삭제 완료',
        description: '예약을 삭제했습니다.',
      });
      mutate();
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message || '삭제 중 오류가 발생했습니다.',
      });
      return;
    }
    setIsDialogOpen(false);
  };

  return (
    <ListBox
      onClick={() => history.push(`${PRIVATE_ROUTE.RESERVATIONS}/view/${_id}`)}
    >
      <ListContent>
        <Label>{user?.name || ''}</Label>
        <ListContentValue>{vehicle}</ListContentValue>
      </ListContent>
      <ListContent>
        <HStack>
          <Tag marginRight={status !== '03' ? 2 : 0}>
            {getStatusByData(status)}
          </Tag>
          {status !== '03' && (
            <Center
              height="100%"
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                setIsDialogOpen(true);
              }}
            >
              <FaRegTrashAlt size="1.2rem" color="#999A9A" />
            </Center>
          )}
        </HStack>
        <span style={{ textAlign: 'right', marginTop: 4 }}>
          {format(new Date(startAt), 'yyyy-MM-dd')} ~{' '}
          <span style={{ display: 'inline-block' }}>
            {format(new Date(endAt), 'yyyy-MM-dd')}
          </span>
        </span>
      </ListContent>
      <Dialog
        title="방문예약 삭제"
        description="해당 예약을 정말 삭제하시겠습니까?"
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={onClickDelete}
      />
    </ListBox>
  );
};

export default ReservationCard;

const Label = styled.span`
  color: #a1a6ad;
  font-weight: 400;
  font-size: 14px;
`;

const ListBox = styled.div`
  display: flex;
  background-color: rgb(247, 246, 250);
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
  min-height: 6rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const ListContent = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &:last-child {
    align-items: flex-end;
  }
`;

const ListContentValue = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
`;
