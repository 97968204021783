import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Textarea,
  Divider,
  Button,
  Box,
  Center,
  Spinner,
} from '@chakra-ui/react';
import useSWR from 'swr';
import styled from 'styled-components';

import StackWrapper from '../../components/StackWrapper';
import useAxios from '../../hooks/useAxios';

import { format } from '../../utils/date';
import FeedbackThread, { IFeedbackThread } from './FeedbackThread';

type Thread = IFeedbackThread & { _id: string };

const feedbackToThread = (feedback: any): Thread | undefined =>
  feedback
    ? {
        _id: feedback._id,
        creatorName: feedback.author?.info?.name || feedback.author?.name,
        createdAt: feedback.createdAt,
        content: feedback.content,
      }
    : undefined;

const vocToThread = (voc: any): Thread | undefined =>
  voc?.result?.defailForCustomer
    ? {
        _id: voc._id,
        creatorName: voc.result?.manager,
        createdAt: voc.result?.completeAt,
        content: voc.result?.defailForCustomer,
      }
    : undefined;

const useFeedbackThread = (data: any): Thread[] =>
  useMemo(
    () =>
      [
        vocToThread(data?.feedback?.voc),
        feedbackToThread(data?.feedback),
        ...(data?.docs ?? []).map(feedbackToThread),
      ]
        .filter((x): x is Thread => x !== undefined)
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
    [data]
  );

const Feedbacks: React.FC = () => {
  const axios = useAxios();
  const { feedbackId: parent } = useParams<{ feedbackId: string }>();
  const { data, mutate } = useSWR(`/feedbacks/${parent}`);

  const thread = useFeedbackThread(data);
  const [content, setContent] = useState('');

  const createThread = useCallback(
    () =>
      axios
        .post(`/feedbacks`, { content, parent })
        .then(() => [setContent(''), mutate()]),
    [axios, content, parent, mutate]
  );

  return (
    <StackWrapper title="불편신고 정보">
      {!!data ? (
        <>
          <FeedbackTitle>{data?.feedback?.title}</FeedbackTitle>
          <Timestamp>{format(data?.feedback?.createdAt)}</Timestamp>
          <Divider my={4} />
          {thread.map(({ _id, ...props }) => (
            <FeedbackThread key={_id} {...props} />
          ))}
          {thread.length > 1 && (
            <>
              <Divider my={4} />
              <Box>
                <Textarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="내용을 입력하세요"
                />
                <Button
                  size="natural"
                  mt={4}
                  variant="brand"
                  onClick={createThread}
                >
                  등록하기
                </Button>
              </Box>
            </>
          )}
        </>
      ) : (
        <Center minHeight={500}>
          <Spinner />
        </Center>
      )}
    </StackWrapper>
  );
};

export default Feedbacks;

const FeedbackTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 0;
`;

const Timestamp = styled.h2`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0;
`;
