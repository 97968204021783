import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Box, Center, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';

import TabBar from './TabBar';

interface IStackWrapper {
  title: string;
  goBackUrl?: string;
  hideBack?: boolean;
}

const StackWrapper: React.FC<IStackWrapper> = ({
  title,
  children,
  goBackUrl,
  hideBack = false,
}) => {
  const history = useHistory();

  return (
    <Container>
      <Box
        width="100%"
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          overflow: 'hidden',
        }}
      >
        <ScrollView>
          <StyledCenter paddingBottom={7} height="64px">
            {!hideBack && (
              <FiArrowLeft
                size="1.8rem"
                color="#2B2929"
                style={{
                  position: 'absolute',
                  left: 0,
                }}
                cursor="pointer"
                onClick={() => {
                  if (goBackUrl) history.replace(goBackUrl);
                  else history.goBack();
                }}
              />
            )}
            <Heading size="md">{title}</Heading>
          </StyledCenter>
          {children}
          <div style={{ width: '100%', height: '1.5rem' }} />
        </ScrollView>
        <TabBar />
      </Box>
    </Container>
  );
};

export default StackWrapper;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScrollView = styled.div`
  overflow-y: auto;
  padding: 0 1.5rem;
  height: 100vh;
  height: calc(100vh - 56px);
  height: calc(100vh - 56px - 1.25rem);
  height: calc(100vh - 56px - constant(safe-area-inset-bottom) - 1.25rem);
  height: calc(100vh - 56px - env(safe-area-inset-bottom) - 1.25rem);

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCenter = styled(Center)`
  margin-top: 1.25rem;
  margin-top: calc(constant(safe-area-inset-bottom) + 1.25rem);
  margin-top: calc(env(safe-area-inset-bottom) + 1.25rem);
  position: relative;
`;
