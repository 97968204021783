import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import {
  useDisclosure,
  Flex,
  VStack,
  Box,
  Divider,
  Collapse,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Text,
} from '@chakra-ui/react';
import { AiOutlineCar } from 'react-icons/ai';
import { formatDistanceToNow } from '../../../utils/date';
import { useHistory } from 'react-router-dom';

interface VehicleSelectCardProps {
  data: any;
}

type DiscountClassCode = '01' | '02' | '03' | '04';

const DiscountClssCodeNames = {
  '01': '종일',
  '02': '시간',
  '03': '정액',
  '04': '정률',
};

const DiscountClssCodeUnits = {
  '01': '일',
  '02': '분',
  '03': '원',
  '04': '%',
};

interface DiscountCouponKey {
  _id: string;
  name: string;
  discountClssCode: DiscountClassCode;
  value: any;
}

const VehicleSelectCard: React.FC<VehicleSelectCardProps> = ({ data }) => {
  const history = useHistory();
  const { isOpen, onToggle } = useDisclosure();

  const keys = useMemo<DiscountCouponKey[]>(
    () =>
      data?.payment?.discountCouponList
        ?.map((coupon: any) => coupon.discountCouponKey)
        .filter((key?: DiscountCouponKey) => !!key),
    [data]
  );

  return (
    <ShadowBox onClick={() => history.push('/main', { vehicle: data.vehicle })}>
      <Flex width="100%" align="flex-end">
        <IconWrap>
          <AiOutlineCar size="2rem" />
        </IconWrap>

        <VStack align="flex-start" paddingLeft={3} flex={1}>
          <Text fontSize="lg" fontWeight="bold">
            {data.vehicle}
          </Text>

          <Text color="#8C9597" style={{ marginTop: 0 }}>
            {formatDistanceToNow(data.entry.accessedAt)} 주차중
          </Text>
        </VStack>

        {keys?.length ? (
          <VStack
            paddingLeft={1}
            onClick={(e) => {
              onToggle();
              e.stopPropagation();
            }}
          >
            <Text fontWeight="bold">할인권 {keys.length}개</Text>

            <Text color="#8C9597" style={{ marginTop: 0 }}>
              {isOpen ? '접기' : '펼치기'}
            </Text>
          </VStack>
        ) : null}
      </Flex>

      {keys?.length ? (
        <FullWidthCollapse in={isOpen} animateOpacity>
          <Box width="100%">
            <Divider orientation="horizontal" marginY={2} />
            <Table size="sm" variant="unstyled">
              <Thead>
                <Tr>
                  <Th>할인내역</Th>
                  <Th>종류</Th>
                  <Th textAlign="right">값</Th>
                </Tr>
              </Thead>
              <Tbody>
                {keys.map((key, i) => (
                  <Tr key={key._id + i}>
                    <Td>{key.name}</Td>
                    <Td>{DiscountClssCodeNames[key.discountClssCode]}</Td>
                    <Td textAlign="right">
                      {key.value.toLocaleString()}
                      {DiscountClssCodeUnits[key.discountClssCode]}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </FullWidthCollapse>
      ) : null}
    </ShadowBox>
  );
};

export default VehicleSelectCard;

const ShadowBox = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.95rem;
  font-weight: bold;
  min-height: 6rem;
  padding: 1rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  cursor: pointer;
  flex-direction: column;
`;

const IconWrap = styled.div`
  background-color: #f3f9f9;
  height: 3.5rem;
  min-width: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullWidthCollapse = styled(Collapse)`
  width: 100%;
`;
