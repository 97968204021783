import React, { useCallback, useMemo, useState } from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Heading,
  Tabs,
  VStack,
  Button,
  Spinner,
  useToast,
  Flex,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { AiOutlineCar } from 'react-icons/ai';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import { userInfoState } from '../../recoil/auth';
import ServiceWrapper from '../../components/ServiceWrapper';
import {
  format,
  formatDistanceToExit,
  formatDistanceToNow,
} from '../../utils/date';
import useAxios from '../../hooks/useAxios';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';
import HistoryCard from '../../components/HistoryCard';
import VehicleInput from '../../components/VehicleInput';
import { useTossPayments } from '../../hooks/useTossPayments';

const AlignedVStack: React.FC = ({ children }) => (
  <VStack align="flex-start" width="100%" py={4}>
    {children}
  </VStack>
);

const StyledHeading: React.FC = ({ children }) => (
  <Heading size="md" marginBottom={3}>
    {children}
  </Heading>
);

const Calculate = () => {
  const axios = useAxios();
  const history = useHistory();
  const tossPayments = useTossPayments();

  const toast = useToast({
    title: '결제에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });
  const userInfo = useRecoilValue(userInfoState)!;
  const vehicle = useMemo(() => userInfo?.vehicle?.plateNumber, [userInfo]);

  const { data, isValidating, mutate, error } = useSWR(
    vehicle ? `/settlements?plateNumber=${vehicle}` : null
  );

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (method: '카드' | '토스페이') => {
      if (loading || !tossPayments) return;
      setLoading(true);
      try {
        const res = await axios.post('/settlements', {
          vehicleAccess: data?._id,
        });
        await tossPayments.requestPayment(method, res.data);
      } catch (err: any) {
        if (err.code !== 'USER_CANCEL') {
          toast({
            title: '결제에 실패했습니다.',
            description:
              err?.response?.data?.message || '결제 중 오류가 발생했습니다.',
          });
        }
      } finally {
        mutate();
        setLoading(false);
      }
    },
    [axios, data?._id, loading, mutate, toast, tossPayments]
  );

  const { data: paymentData } = useSWR('/vehicle-payments');
  // `/vehicle-payments${vehicle ? `?vehicle=${vehicle}` : ''}`

  return (
    <ServiceWrapper title="내차정산">
      <Tabs isFitted colorScheme="brand">
        <TabList mb="1em">
          <Tab>사전정산</Tab>
          <Tab>정산내역</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VehicleInput vehicle={vehicle} />
            {vehicle && (
              <PositionShadowBox>
                {!error && !isValidating ? (
                  data ? (
                    <>
                      <AiOutlineCar size="2rem" />
                      <Title>{data.site.name}에 주차 중입니다.</Title>
                    </>
                  ) : (
                    <Spinner />
                  )
                ) : !isValidating ? (
                  '주차중이 아니거나 이미 사전정산한 차량입니다.'
                ) : (
                  <Spinner />
                )}
              </PositionShadowBox>
            )}
            {/* <ShadowBox>
              <HStack justify="flex-start" width="100%">
                <HStack align="center">
                  <IconWrap>
                    <AiOutlineCreditCard size="2rem" />
                  </IconWrap>
                  <VStack align="flex-start" paddingLeft={1}>
                    <Text color="#8C9597">결제카드</Text>
                    <Text
                      fontSize="lg"
                      fontWeight="bold"
                      style={{ marginTop: 0 }}
                    >
                      ****-****-****-*082
                    </Text>
                  </VStack>
                </HStack>
                <PrimaryButton style={{ marginLeft: 'auto' }}>
                  수정
                </PrimaryButton>
              </HStack>
            </ShadowBox> */}
            {data && !error && (
              <>
                <AlignedVStack>
                  <StyledHeading>상세내역</StyledHeading>
                  <InfoShadowBox>
                    <InfoRow>
                      <span>입차시각</span>
                      <span>{format(data.entry.accessedAt)}</span>
                    </InfoRow>
                    <InfoRow>
                      <span>이용시간</span>
                      <span>
                        {data?.exit?.accessedAt
                          ? formatDistanceToExit(
                              data.entry.accessedAt,
                              data.exit.accessedAt
                            )
                          : formatDistanceToNow(data.entry.accessedAt)}
                      </span>
                    </InfoRow>
                    <InfoRow>
                      <span>주차요금</span>
                      <span>{data.payment.totalCost.toLocaleString()}원</span>
                    </InfoRow>
                  </InfoShadowBox>
                </AlignedVStack>
                <AlignedVStack>
                  <StyledHeading>할인수단</StyledHeading>
                  <DiscountType>
                    <span>할인권</span>
                    <Flex align="center">
                      <DiscountText>
                        {data.payment.discountCouponList.length ? (
                          <span
                            style={{ color: '#cd0f46' }}
                          >{`${data.payment.discountCouponList.length.toLocaleString()}장 적용`}</span>
                        ) : (
                          '미적용'
                        )}
                      </DiscountText>
                      <DiscountButton
                        onClick={() =>
                          history.push(
                            `${PRIVATE_ROUTE.CALCULATE}/coupons?vehicleAccess=${data?._id}&plateNumber=${vehicle}`
                          )
                        }
                      >
                        할인권 선택
                      </DiscountButton>
                    </Flex>
                  </DiscountType>
                  <CalcRow>
                    <span>할인금액</span>
                    <span style={{ color: '#cd0f46' }}>
                      {data.payment.discountCost === 0
                        ? '0'
                        : data.payment.discountCost.toLocaleString()}
                      원
                    </span>
                  </CalcRow>
                  <CalcRow>
                    <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                      최종 결제금액
                    </span>
                    <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                      {data.payment.totalCost.toLocaleString()}원
                    </span>
                  </CalcRow>
                </AlignedVStack>
                {data.payment.totalCost !== 0 && (
                  <VStack>
                    <Button
                      variant="brand"
                      size="natural"
                      width="100%"
                      onClick={() => onSubmit('카드')}
                    >
                      카드 결제하기
                    </Button>
                    <Button
                      variant="brand"
                      size="natural"
                      width="100%"
                      onClick={() => onSubmit('토스페이')}
                    >
                      토스페이 결제하기
                    </Button>
                  </VStack>
                )}
              </>
            )}
          </TabPanel>
          <TabPanel>
            {(!paymentData || !paymentData.payments.length) && (
              <Alert status="warning" marginBottom={8}>
                <AlertIcon />
                내역이 없습니다.
              </Alert>
            )}
            <VStack spacing={4} width="100%">
              {paymentData?.payments.map(
                (payment: any, paymentIndex: number) => (
                  <HistoryCard
                    key={paymentIndex}
                    status={payment?.paidType}
                    statusText={{
                      '01': '사전정산',
                      '02': '출구정산',
                      '03': '후불정산',
                    }}
                    vehicle={payment?.vehicle}
                    amount={payment?.price?.total}
                    createdAt={format(payment?.paidAt)}
                  />
                )
              )}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ServiceWrapper>
  );
};

export default Calculate;

const ShadowBox = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.95rem;
  font-weight: bold;
  height: 6rem;
  padding: 1rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  border-radius: 1rem;
`;

const Title = styled.span`
  margin: 0 auto;
  height: auto;
`;

const PositionShadowBox = styled(ShadowBox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-bottom: 10px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  & > span:first-of-type {
    font-weight: normal;
    margin-right: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoShadowBox = styled(ShadowBox)`
  flex-direction: column;
  height: auto;
`;

const DiscountType = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
  justify-content: space-between;

  & > span:first-of-type {
    font-weight: normal;
  }
`;

const DiscountButton = styled.button`
  padding: 5px;
  border: 1px solid #cd0f46;
  color: #cd0f46;
  font-weight: normal;
  border-radius: 0.7rem;
`;

const CalcRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.3rem;

  & span {
    font-weight: normal;
  }

  &:last-child {
    margin-bottom: 1rem;
  }
`;

const DiscountText = styled.span`
  margin-right: 8px;
`;
