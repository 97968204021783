import React from 'react';
import { useHistory } from 'react-router-dom';
import { HiXCircle } from 'react-icons/hi';
import { Button, Center, Heading, Text} from '@chakra-ui/react';

import {
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import StackWrapper from '../../components/StackWrapper';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';

const PaymentSuccess: React.FC = () => {
  const history = useHistory();

  const [{ message }] = useQueryParams({
    message: withDefault(StringParam, ''),
  });


  return (
    <StackWrapper title="결제 취소" hideBack>
      <>
        <Center flex={1} flexDirection="column" align="center">
          <HiXCircle
            color="#cd0f46"
            size="6.5rem"
            style={{
              marginBottom: '1.2rem',
            }}
          />
          <Heading>결제 취소</Heading>

          <Text
            color="rgba(0, 0, 0, 0.95)"
            align="center"
            marginTop={4}
            fontSize="md"
          >
            결제가 취소되었습니다.
            <br />
            {message && <Text marginTop={1}>{message}</Text>}
          </Text>
          <Button
            marginTop={8}
            marginBottom="82px"
            variant="brand"
            onClick={() => history.push(PRIVATE_ROUTE.MAIN)}
          >
            메인으로
          </Button>
        </Center>
      </>
    </StackWrapper>
  );
};

export default PaymentSuccess;
