import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  InputGroup,
} from '@chakra-ui/react';

import StackWrapper from '../../components/StackWrapper';
import AbsoluteButton from '../../components/AbsoluteButton';
import RightButton from '../../components/RightButton';
import Message from '../../components/Message';

import useAxios from '../../hooks/useAxios';
import { userInfoState } from '../../recoil/auth';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';
import { phoneNormalizer } from '../../utils/normalizers';

const INITIAL_LAST_TIME = 60 * 3;

const Profile: React.FC = () => {
  const history = useHistory();
  const axios = useAxios();
  const toast = useToast({
    title: '프로필 정보 수정에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);
  const [certiSn, setCertiSn] = useState<string>('');
  const [certiNo, setCertiNo] = useState<string>('');
  const [phoneMessage, setPhoneMessage] = useState<string>('');
  const [lastTime, setLastTime] = useState<number>(INITIAL_LAST_TIME);
  const [startCount, setStartCount] = useState<boolean>(false);

  const [payload, setPayload] = useState({
    name: userInfo?.info?.name,
    phone: userInfo?.info?.phone,
    email: userInfo?.email,
    department: userInfo?.info?.department || '',
    position: userInfo?.info?.position || '',
  });

  useEffect(() => {
    if (startCount) {
      const interval = setTimeout(() => {
        setLastTime(lastTime - 1);
      }, 1000);
      return () => clearTimeout(interval);
    }
    return () => {};
  }, [startCount, lastTime]);

  const ellapsedTime = useMemo(() => {
    const minutes = Math.floor(lastTime / 60);
    return `${minutes}분 ${lastTime - minutes * 60}초`;
  }, [lastTime]);

  const onClickVerifyPhone = async () => {
    setIsPhoneVerified(false);

    try {
      const {
        data: { certiSn },
      } = await axios.get(`/cert-phone/${payload.phone}`);
      setCertiSn(certiSn);
      toast({ title: '문자로 인증번호를 전송했습니다.', status: 'success' });
      setStartCount(true);
    } catch (err) {
      toast({ title: '인증번호 전송에 실패했습니다.' });
    }
  };

  const onClickCheckCert = async () => {
    try {
      const { data } = await axios.get(
        `/cert-phone/${payload.phone}/verify?certiSn=${certiSn}&certiNo=${certiNo}`
      );
      if (data) {
        toast({ title: '인증에 성공했습니다.', status: 'success' });
        setIsPhoneVerified(true);
        setPhoneMessage('');
        setStartCount(false);
      } else {
        toast({ title: '인증에 실패했습니다.' });
      }
    } catch (err) {
      toast({ title: '인증에 실패했습니다.' });
    }
  };

  const onSubmit = async () => {
    if (loading) return;

    const isPhoneChanged = userInfo?.info?.phone !== payload.phone;
    if (isPhoneChanged && !isPhoneVerified) {
      setPhoneMessage('전화번호 인증을 해주세요!');
      return;
    }
    setCertiSn('');
    setCertiNo('');
    setLoading(true);
    try {
      await axios.patch('/users/me', {
        info: {
          name: payload.name || undefined,
          phone: payload.phone || undefined,
          department: payload.department || undefined,
          position: payload.position || undefined,
        },
      });
      toast({
        status: 'success',
        title: '성공',
        description: '수정이 완료되었습니다.',
      });
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message || '수정 중 오류가 발생했습니다.',
      });
    } finally {
      setIsEditable(false);
      axios.get('/users/me').then(({ data }) => {
        setUserInfo(data);
        setPayload({
          name: data?.info?.name,
          email: userInfo?.email,
          phone: data?.info?.phone,
          department: data?.info?.department,
          position: data?.info?.position,
        });
      });
      setLoading(false);
    }
  };

  return (
    <StackWrapper title="내 프로필">
      <VStack spacing={6}>
        <FormControl>
          <FormLabel>이름</FormLabel>
          <Input
            name="name"
            disabled={!isEditable}
            value={payload.name}
            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>이메일</FormLabel>
          <Input name="email" disabled={true} value={payload.email} />
        </FormControl>
        <FormControl>
          <FormLabel>전화번호</FormLabel>
          <InputGroup>
            <Input
              pr="5.5rem"
              name="phone"
              value={payload.phone}
              disabled={!isEditable}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  phone: phoneNormalizer(e.target.value),
                })
              }
            />
            {isEditable && (
              <RightButton
                style={{
                  height: '3rem',
                  marginRight: '0.5rem',
                }}
                onClick={onClickVerifyPhone}
                disabled={!!certiSn && !!lastTime}
              >
                {!!certiSn ? '재발송' : '인증번호 발송'}
              </RightButton>
            )}
          </InputGroup>
          {phoneMessage && <Message>{phoneMessage}</Message>}
          {!!certiSn && lastTime && (
            <Message>재전송 제한 시간: {ellapsedTime}</Message>
          )}
        </FormControl>
        {!!certiSn && (
          <FormControl>
            <FormLabel>인증번호</FormLabel>
            <InputGroup>
              <Input
                pr="5.5rem"
                name="phone"
                value={certiNo}
                disabled={isPhoneVerified}
                onChange={(e) => setCertiNo(e.target.value)}
              />
              <RightButton
                style={{
                  height: '3rem',
                  marginRight: '0.5rem',
                }}
                onClick={onClickCheckCert}
              >
                인증번호 확인
              </RightButton>
            </InputGroup>
          </FormControl>
        )}
        <FormControl>
          <FormLabel>부서</FormLabel>
          <Input
            name="department"
            value={payload.department}
            disabled={!isEditable}
            onChange={(e) =>
              setPayload({ ...payload, department: e.target.value })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>직책</FormLabel>
          <Input
            name="position"
            value={payload.position}
            disabled={!isEditable}
            onChange={(e) =>
              setPayload({ ...payload, position: e.target.value })
            }
          />
        </FormControl>
      </VStack>
      {isEditable ? (
        <AbsoluteButton
          containerStyle={{ position: 'unset', marginTop: 32 }}
          onClick={onSubmit}
          isLoading={loading}
        >
          저장하기
        </AbsoluteButton>
      ) : (
        <>
          <AbsoluteButton
            containerStyle={{ position: 'unset', marginTop: 32, flex: 0 }}
            variant="outline"
            onClick={() => history.push(`${PRIVATE_ROUTE.PROFILE_PASSWORD}`)}
          >
            비밀번호 수정하기
          </AbsoluteButton>
          <AbsoluteButton
            containerStyle={{ position: 'unset', marginTop: 8 }}
            onClick={() => setIsEditable(true)}
          >
            정보 수정하기
          </AbsoluteButton>
        </>
      )}
    </StackWrapper>
  );
};

export default Profile;
