import React from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';

interface IColumnCard {
  columns: {
    icon?: React.ReactNode;
    field: string;
    value: string;
  }[];
}

const ColumnCard: React.FC<IColumnCard> = ({ columns }) => (
  <Box
    width="100%"
    padding={5}
    style={{
      boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.08)',
      borderRadius: '0.375rem',
    }}
  >
    <VStack align="flex-start">
      {columns.map(({ icon, field, value }, index) => (
        <HStack
          key={`column-${index}`}
          align="center"
          marginBottom={index === columns.length - 1 ? 0 : 2}
        >
          <IconWrap>{icon}</IconWrap>
          <VStack align="flex-start" paddingLeft={1}>
            <Text color="#8C9597">{field}</Text>
            <Text fontSize="lg" fontWeight="bold" style={{ marginTop: 0 }}>
              {value}
            </Text>
          </VStack>
        </HStack>
      ))}
    </VStack>
  </Box>
);

export default ColumnCard;

const IconWrap = styled.div`
  background-color: #f3f9f9;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
