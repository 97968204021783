import React from 'react';
import { Divider } from '@chakra-ui/react';
import styled from 'styled-components';

import { format } from '../../utils/date';

export interface IFeedbackThread {
  creatorName: string;
  createdAt: string;
  content: string;
}

const FeedbackThread: React.FC<IFeedbackThread> = ({
  creatorName,
  createdAt,
  content,
}) => {
  return (
    <Box>
      <CreatorName>{creatorName}</CreatorName>
      <Timestamp>{format(createdAt)}</Timestamp>
      <Divider style={{ margin: '12px 0' }} />
      <Content>{content}</Content>
    </Box>
  );
};

export default FeedbackThread;

const Box = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid rgb(228, 230, 233);
  border-left-width: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const CreatorName = styled.h1`
  font-size: 16px;
  margin-bottom: 2px;
`;

const Timestamp = styled.h2`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
`;

const Content = styled.p`
  font-size: 16px;
`;
