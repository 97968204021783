import React from 'react';
import { InputRightElement, Button, ButtonProps } from '@chakra-ui/react';

const RightButton: React.FC<ButtonProps> = ({ style, children, ...props }) => {
  return (
    <InputRightElement width="5.5rem" padding={0} style={style}>
      <Button
        {...props}
        height="2rem"
        width="100%"
        fontSize="0.8rem"
        fontWeight="normal"
        variant="brand"
        _hover={{}}
      >
        {children}
      </Button>
    </InputRightElement>
  );
};

export default RightButton;
