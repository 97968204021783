import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { QueryParamProvider } from 'use-query-params';

import { publicRoutes, privateRoutes } from './routes';
import PrivateRoute from './PrivateRoute';
import { accessTokenState, userInfoState } from '../recoil/auth';
import useAxios from '../hooks/useAxios';

export default function Router() {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <TheSwitch />
      </QueryParamProvider>
    </BrowserRouter>
  );
}

function TheSwitch() {
  const axios = useAxios();
  const setUserInfo = useSetRecoilState(userInfoState);
  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!accessToken) {
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get('/users/me')
      .then((value) => {
        setUserInfo(value.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, axios, setUserInfo]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      ></div>
    );
  }

  return (
    <Switch>
      {publicRoutes.map(({ exact = true, path, component, ...otherProps }) => (
        <Route
          key={`public-route-${path}`}
          exact={exact}
          path={path}
          component={component}
          {...otherProps}
        />
      ))}
      {privateRoutes.map(
        ({ exact = true, path, component, children, ...otherProps }) =>
          children
            ? children.map(({ exact: childExact = true, ...child }) => (
                <PrivateRoute
                  key={`private-route-${path}`}
                  exact={childExact}
                  path={child.path}
                  component={child.component}
                />
              ))
            : path && (
                <PrivateRoute
                  key={`private-route-${path}`}
                  exact={exact}
                  path={path}
                  component={component}
                  {...otherProps}
                />
              )
      )}
    </Switch>
  );
}
