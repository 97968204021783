import React from 'react';
import { useHistory } from 'react-router-dom';
import { RiCarLine } from 'react-icons/ri';
import { Center, Text, VStack } from '@chakra-ui/react';
import useSWR from 'swr';

import StackWrapper from '../../components/StackWrapper';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';

import VehicleCard from './components/VehicleCard';
import AbsoluteButton from '../../components/AbsoluteButton';

const Vehicles: React.FC = () => {
  const history = useHistory();
  const { data: vehicle, mutate } = useSWR('/vehicle');

  return (
    <StackWrapper title="차량 관리" goBackUrl={PRIVATE_ROUTE.MORE}>
      {vehicle ? (
        <>
          <VStack spacing={4} width="100%">
            <VehicleCard {...vehicle} mutate={mutate} />
          </VStack>
          {/* <AbsoluteButton
            containerStyle={{ position: 'unset', marginTop: 16 }}
            onClick={() => history.push(`${PRIVATE_ROUTE.VEHICLES}/register`)}
          >
            차량 추가 등록하기
          </AbsoluteButton> */}
        </>
      ) : (
        <>
          <Center flex={1} flexDirection="column" align="center">
            <RiCarLine
              color="rgba(0, 0, 0, 0.95"
              size="6.5rem"
              style={{
                marginBottom: '1.2rem',
              }}
            />
            <Text color="rgba(0, 0, 0, 0.95)" align="center" fontSize="md">
              등록된 차량이 없습니다.
              <br />
              차량을 등록하고 서비스를 이용해 보세요.
            </Text>
          </Center>
          <AbsoluteButton
            onClick={() =>
              history.replace(`${PRIVATE_ROUTE.VEHICLES}/register`)
            }
          >
            차량 등록하기
          </AbsoluteButton>
        </>
      )}
    </StackWrapper>
  );
};

export default Vehicles;
