import React from 'react';
import { useHistory } from 'react-router-dom';
import { VStack, Text, Center, Spinner } from '@chakra-ui/react';
import useSWR from 'swr';

import StackWrapper from '../../components/StackWrapper';
import FeedbackItem, { IFeedbackItem } from './FeedbackItem';
import AbsoluteButton from '../../components/AbsoluteButton';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';

const Feedbacks: React.FC = () => {
  const history = useHistory();
  const { data } = useSWR('/feedbacks');

  return (
    <StackWrapper title="불편신고">
      {!!data?.docs ? (
        data.docs.length ? (
          <VStack>
            {data?.docs.map(
              (feedback: IFeedbackItem, feedbackIndex: number) => (
                <FeedbackItem key={feedbackIndex} {...feedback} />
              )
            )}
          </VStack>
        ) : (
          <Text color="rgba(0, 0, 0, 0.95)" align="center" fontSize="md">
            등록된 불편신고가 없습니다.
          </Text>
        )
      ) : (
        <Center minHeight={500}>
          <Spinner />
        </Center>
      )}
      <AbsoluteButton
        onClick={() => history.push(`${PRIVATE_ROUTE.FEEDBACKS}/create`)}
      >
        등록하기
      </AbsoluteButton>
    </StackWrapper>
  );
};

export default Feedbacks;
