import React from 'react';
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  List,
  ListItem,
  ListIcon,
  Divider,
} from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import gfm from 'remark-gfm';

import { format } from '../../utils/date';
import useAxios from '../../hooks/useAxios';

interface IFile {
  _id: string;
  createdAt: string;
  folder: string;
  name: string;
  temp: boolean;
  type: string;
  updatedAt: string;
}

export interface INoticeItem {
  title: string;
  content: string;
  createdAt: string;
  files: IFile[];
  onClick?: (e: any) => void;
}

const NoticeItem: React.FC<INoticeItem> = ({
  title,
  content,
  createdAt,
  files,
  onClick,
}) => {
  const axios = useAxios();

  const onClickDownload = async ({ _id: fileId, type, name }: IFile) => {
    const { data } = await axios.get(`/files/${fileId}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: type,
      },
    });
    const blob = new Blob([data], { type });
    const src = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', src);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AccordionItem onClick={onClick}>
      <AccordionButton py={4} px={2}>
        <Box
          flex="1"
          textAlign="left"
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '0.8rem',
            maxWidth: 'calc(100% - 1.8rem)',
          }}
        >
          <Title>{title}</Title>
          <CreatedAt>{format(createdAt)}</CreatedAt>
        </Box>
        {(content || !!files.length) && <AccordionIcon />}
      </AccordionButton>
      {(content || !!files.length) && (
        <AccordionPanel
          display="flex"
          flexDirection="column"
          py={6}
          color="rgba(0, 0, 0, 0.7)"
        >
          <ReactMarkdown
            remarkPlugins={[gfm]}
            components={ChakraUIRenderer()}
            children={content}
          />
          {!!files.length && (
            <>
              <Divider my={4} />
              <List spacing={3}>
                {files.map((fileInfo, fileIndex) => (
                  <ListItem
                    key={fileIndex}
                    onClick={() => onClickDownload(fileInfo)}
                    style={{ cursor: 'pointer' }}
                  >
                    <ListIcon as={AttachmentIcon} color="green.500" />
                    {fileInfo.name}

                    {fileInfo.type?.startsWith('image/') && (
                      <img alt={fileInfo.name} src={`/files/${fileInfo._id}`} />
                    )}
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </AccordionPanel>
      )}
    </AccordionItem>
  );
};

export default NoticeItem;

const Title = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
  font-weight: 600;
`;

const CreatedAt = styled.span`
  color: #a1a6ad;
  font-size: 14px;
`;
