import { atom, useResetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';

import { PUBLIC_ROUTE } from '../routes/routes.constants';
import localStorageEffect from './effects/localStorageEffect';

export const accessTokenState = atom({
  key: 'accessTokenState',
  default: '',
  effects_UNSTABLE: [localStorageEffect('accessTokenState')],
});

export const refreshTokenState = atom({
  key: 'refreshTokenState',
  default: '',
  effects_UNSTABLE: [localStorageEffect('refreshTokenState')],
});

export const useLogout = () => {
  const history = useHistory();

  const resetAccessToken = useResetRecoilState(accessTokenState);
  const resetRefreshToken = useResetRecoilState(refreshTokenState);

  return () => {
    resetAccessToken();
    resetRefreshToken();
    history.push(PUBLIC_ROUTE.LOGIN);
  };
};

export interface UserInfo {
  email: string;
  info: {
    name: string;
    phone: string;
    department?: string;
    position?: string;
  };
  remarks?: string | null;
  _id: string;
  site: {
    owner: {
      name: string;
      email: string;
    };
    _id: string;
    siteType: string;
    name: string;
    postNumber?: string;
    address1?: string;
    address2: string;
    center?: string;
    building?: string;
    client: string;
    parkingSpaceNumber: number;
    closingHour: number;
    fax: string;
    tel: string;
    businessName?: string;
    businessNumber?: string;
    region?: string;
    autoApproveSeasonTicket?: boolean;
  };
  vehicle?: {
    plateNumber: string;
    modelName?: string;
    selectDefault?: boolean;
  };
}

export const userInfoState = atom<UserInfo | undefined>({
  key: 'userInfoState',
  default: undefined,
});
