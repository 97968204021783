import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import StackWrapper from '../../components/StackWrapper';

import useAxios from '../../hooks/useAxios';
import useSWRInfinite from 'swr/infinite';

import { useRecoilValue } from 'recoil';
import { useHistory, useParams } from 'react-router-dom';
import { UserInfo, userInfoState } from '../../recoil/auth';
import useOnScreen from '../../utils/useOnScreen';
import Dialog from '../../components/Dialog';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';
import { stringifyUrl } from 'query-string';

interface UserBoxProps {
  user: UserInfo;
  mutate: () => void;
}

const UserBox = ({ user, mutate }: UserBoxProps) => {
  const axios = useAxios();
  const history = useHistory();
  const { ticketId: id } = useParams<{ ticketId: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toast = useToast({
    title: '양도 실패',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });

  const onClickTransfer = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      await axios.post(`/season-tickets/${id}/transfer`, { user: user._id });

      mutate();
      history.push(PRIVATE_ROUTE.SEASON_TICKETS);

      toast({
        status: 'success',
        title: '양도 완료',
        description: '정기권을 양도했습니다.',
      });
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message || '정기권 양도 중 오류가 발생했습니다.',
      });
    } finally {
      setIsLoading(false);
      setIsDialogOpen(false);
    }
  }, [axios, history, isLoading, mutate, id, toast, user]);

  return (
    <Box
      background="white"
      padding={4}
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '0.375rem',
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.08)',
        gap: '8px',
      }}
      width="100%"
      cursor="pointer"
      onClick={() => setIsDialogOpen(true)}
    >
      <Heading fontSize="md">{user.info.name}</Heading>
      <div style={{ backgroundColor: '#999A9A', width: 1, height: 12 }} />
      <Text fontSize="md" color="#999A9A">
        {user.vehicle?.plateNumber}
      </Text>

      <Dialog
        title="정기권 양도"
        buttonText="확인"
        description={
          <Text>
            <b>{user.info.name}</b>님에게 정기권을 양도하시겠습니까?
          </Text>
        }
        onClose={() => setIsDialogOpen(false)}
        onSuccess={onClickTransfer}
        isOpen={isDialogOpen}
        isLoading={isLoading}
      />
    </Box>
  );
};

type Data = { docs: UserInfo[]; meta: any };

const TransferSeasonTicket: FC = () => {
  const axios = useAxios();
  const userInfo = useRecoilValue(userInfoState);

  const ref = useRef<HTMLInputElement>(null);
  const targetRef = useRef<HTMLSpanElement>(null);
  const isVisibleTargetRef = useOnScreen(targetRef);

  const [search, setSearch] = useState<string>();
  const onSearch = useCallback(() => setSearch(ref.current?.value), [ref]);

  const { data, setSize, mutate, isValidating } = useSWRInfinite<Data>(
    (index, data) => {
      const page = index > 0 ? data?.meta?.nextPage : 1;
      return page
        ? stringifyUrl({
            url: '/users',
            query: { search, page, count: 10, hasVehicle: true },
          })
        : null;
    },
    (url, params) => axios.get(url, { params }).then((res) => res.data),
    { revalidateAll: true }
  );

  const users = useMemo(
    () =>
      data
        ?.flatMap((d) => d.docs ?? [])
        ?.filter((v) => v._id !== userInfo?._id),
    [data, userInfo?._id]
  );

  useEffect(
    () => void (isVisibleTargetRef && setSize((size) => size + 1)),
    [isVisibleTargetRef, setSize]
  );

  return (
    <StackWrapper title="정기권 양도">
      <HStack mb={5} justifyItems="stretch">
        <Input
          ref={ref}
          autoFocus
          onKeyPress={(e) => e.key === 'Enter' && onSearch()}
          placeholder="이름 및 차량번호를 입력하세요."
          _placeholder={{ color: '#99999A' }}
          style={{ border: 0, fontSize: '0.95rem', backgroundColor: '#F7F8F9' }}
        />
        <Button
          px={5}
          size="natural"
          variant="brand"
          onClick={onSearch}
          isLoading={isValidating}
        >
          검색
        </Button>
      </HStack>

      {users && users.length > 0 && (
        <VStack mb={5} spacing={4} width="100%">
          {users?.map((user) => (
            <UserBox key={user._id} user={user} mutate={mutate} />
          ))}
        </VStack>
      )}

      {isValidating ? (
        <VStack>
          <Spinner />
        </VStack>
      ) : (
        !users?.length && (
          <Alert status="warning">
            <AlertIcon />
            검색된 사용자가 없습니다.
          </Alert>
        )
      )}

      <span ref={targetRef} />
    </StackWrapper>
  );
};

export default TransferSeasonTicket;
