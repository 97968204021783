import React from 'react';
import loadable from '@loadable/component';
import { PUBLIC_ROUTE, PRIVATE_ROUTE } from './routes.constants';
import { Redirect } from 'react-router';

import {
  Tabs,
  Vehicles,
  Calculate,
  DiscountCoupons,
  Profile,
  ProfilePassword,
  Notices,
  Feedbacks,
  CreateFeedback,
  ViewFeedback,
  PaymentSuccess,
  PaymentCancel,
} from '../pages';
import ReservationSubmit from '../pages/Reservations/ReservationSubmit';
import ReservationView from '../pages/Reservations/ReservationView';
import VehicleSelect from '../pages/Main/VehicleSelect';
import TransferSeasonTicket from '../pages/SeasonTickets/TransferSeasonTicket';

export interface ISidebar {
  label: string;
  icon?: React.ReactNode;
}

export interface IRoute {
  exact?: boolean;
  path?: string;
  component?: any;
  sidebar?: ISidebar;
  hidden?: boolean;
}

export const publicRoutes: Array<IRoute> = [
  {
    path: PUBLIC_ROUTE.ROOT,
    component: () => <Redirect to="/main" />,
  },
  {
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import('../pages/Login/Login')),
  },
  {
    path: PUBLIC_ROUTE.PRIVACY_POLICY,
    component: loadable(() => import('../pages/Login/PrivacyPolicy')),
  },
  {
    path: PUBLIC_ROUTE.SIGNUP,
    component: loadable(() => import('../pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.EMAIL_VERIFY,
    component: loadable(() => import('../pages/EmailVerify/EmailVerify')),
  },
  {
    path: PUBLIC_ROUTE.RECOVER,
    component: loadable(() => import('../pages/Recover/Recover')),
  },
  {
    path: `${PUBLIC_ROUTE.RESET_PASSWORD}/:token`,
    component: loadable(() => import('../pages/Recover/ResetPassword')),
  },
];

interface IPrivateRoute extends Omit<IRoute, 'path'> {
  path?: string;
  children?: Array<IRoute>;
}

export const privateRoutes: Array<IPrivateRoute> = [
  {
    path: PRIVATE_ROUTE.MAIN,
    component: <Tabs.Main />,
  },
  {
    path: `${PRIVATE_ROUTE.MAIN}/vehicle-select`,
    component: <VehicleSelect />,
  },
  {
    path: PRIVATE_ROUTE.PROFILE,
    component: <Profile />,
  },
  {
    path: PRIVATE_ROUTE.PROFILE_PASSWORD,
    component: <ProfilePassword />,
  },
  {
    path: PRIVATE_ROUTE.CALCULATE,
    component: <Tabs.Calculate />,
  },
  {
    path: PRIVATE_ROUTE.DISCOUNT_COUPONS,
    component: <Tabs.DiscountCoupons />,
  },
  {
    path: PRIVATE_ROUTE.SEASON_TICKETS,
    component: <Tabs.SeasonTickets />,
  },
  {
    path: `${PRIVATE_ROUTE.SEASON_TICKETS}/transfer/:ticketId`,
    component: <TransferSeasonTicket />,
  },
  {
    path: PRIVATE_ROUTE.RESERVATIONS,
    component: <Tabs.Reservations />,
  },
  {
    path: `${PRIVATE_ROUTE.RESERVATIONS}/view/:reservationId`,
    component: <ReservationView />,
  },
  {
    path: PRIVATE_ROUTE.RESERVATION_SUBMIT,
    component: <ReservationSubmit />,
  },
  {
    path: PRIVATE_ROUTE.MORE,
    component: <Tabs.More />,
  },

  {
    path: `${PRIVATE_ROUTE.VEHICLES}`,
    component: <Vehicles.Vehicles />,
  },
  {
    path: `${PRIVATE_ROUTE.VEHICLES}/register`,
    component: <Vehicles.VehicleRegister />,
  },

  {
    path: `${PRIVATE_ROUTE.CALCULATE}/coupons`,
    component: <Calculate.CalculateCoupons />,
  },
  {
    path: `${PRIVATE_ROUTE.DISCOUNT_COUPONS}/coupon-keys`,
    component: <DiscountCoupons.DiscountCouponKeys />,
  },
  {
    path: `${PRIVATE_ROUTE.DISCOUNT_COUPONS}/purchase`,
    component: <DiscountCoupons.Purchase />,
  },

  {
    path: PRIVATE_ROUTE.NOTICES,
    component: <Notices />,
  },
  {
    path: PRIVATE_ROUTE.FEEDBACKS,
    component: <Feedbacks />,
  },
  {
    path: `${PRIVATE_ROUTE.FEEDBACKS}/create`,
    component: <CreateFeedback />,
  },
  {
    path: `${PRIVATE_ROUTE.FEEDBACKS}/view/:feedbackId`,
    component: <ViewFeedback />,
  },
  {
    path: PRIVATE_ROUTE.PAYMENT_SUCCESS,
    component: <PaymentSuccess />,
  },
  {
    path: PRIVATE_ROUTE.PAYMENT_CANCEL,
    component: <PaymentCancel />,
  },
];
