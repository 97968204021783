import React, { useMemo, useState } from 'react';
import {
  Divider,
  Flex,
  HStack,
  Text,
  Center,
  VStack,
  Link,
  useToast,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { AiFillNotification } from 'react-icons/ai';
import { RiCarFill, RiFeedbackFill } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { HiUserRemove } from 'react-icons/hi';
import { CgProfile } from 'react-icons/cg';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';
import ServiceWrapper from '../../components/ServiceWrapper';
import { useLogout, userInfoState } from '../../recoil/auth';
import useAxios from '../../hooks/useAxios';
import Dialog from '../../components/Dialog';
import { useRecoilValue } from 'recoil';

interface IMenuItem {
  icon: React.ReactNode;
  title: string;
  route?: string;
  onClick?: () => void;
}

const MenuItem: React.FC<IMenuItem> = ({
  icon,
  title,
  route = '#',
  onClick,
}) => {
  const styledIcon = React.cloneElement(icon as any, {
    color: '#707070',
    size: '1.8rem',
  });
  return (
    <Link
      as={RouterLink}
      to={route}
      style={{ textDecoration: 'none' }}
      _hover={{ backgroundColor: '#f1f3f5' }}
      _active={{ backgroundColor: '#f1f3f5' }}
      onClick={onClick}
    >
      <HStack align="center" px={3} py={4} width="100%">
        <Center width="2rem" height="2rem" marginRight={2}>
          {styledIcon}
        </Center>
        <Text fontSize="xl" color="rgba(0, 0, 0, 0.95)">
          {title}
        </Text>
      </HStack>
    </Link>
  );
};

const More = () => {
  const axios = useAxios();
  const toast = useToast({
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });
  const userInfo = useRecoilValue(userInfoState);
  const isYongsanCustom = useMemo(
    () => userInfo?.site._id === 'LG_UPLUS_YONGSAN',
    [userInfo?.site._id]
  );

  const logout = useLogout();
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState<boolean>(false);

  const onClickLeave = async () => {
    try {
      await axios.delete('/users/me');
      toast({
        title: '서비스를 탈퇴했습니다.',
        description: '로그인 페이지로 이동합니다.',
      });
      logout();
    } catch (err: any) {
      toast({
        title: '서비스 탈퇴에 실패했습니다.',
        description:
          err?.response?.data?.message || '서비스 탈퇴 중 오류가 발생했습니다.',
      });
    }
  };

  return (
    <ServiceWrapper title="더보기">
      <VStack align="flex-start">
        <Divider paddingTop={4} />
        <Flex flexDirection="column" width="100%">
          <MenuItem
            icon={<CgProfile />}
            title="내 프로필"
            route={PRIVATE_ROUTE.PROFILE}
          />
          {!isYongsanCustom && (
            <>
              <MenuItem
                icon={<RiCarFill />}
                title="차량 등록"
                route={PRIVATE_ROUTE.VEHICLES}
              />
              <MenuItem
                icon={<AiFillNotification />}
                title="공지사항"
                route={PRIVATE_ROUTE.NOTICES}
              />
              <MenuItem
                icon={<RiFeedbackFill />}
                title="불편신고"
                route={PRIVATE_ROUTE.FEEDBACKS}
              />
              <br />
            </>
          )}
          <MenuItem icon={<FiLogOut />} title="로그아웃" onClick={logout} />
          <MenuItem
            icon={<HiUserRemove />}
            title="탈퇴하기"
            onClick={() => setIsLeaveDialogOpen(true)}
          />
        </Flex>
      </VStack>
      <Dialog
        title="서비스 탈퇴"
        description={
          <>
            정말로 서비스를 탈퇴하시겠습니까?
            <br />
            탈퇴 시 동일아이디 재 가입 불가합니다.
          </>
        }
        buttonText="탈퇴"
        isOpen={isLeaveDialogOpen}
        onClose={() => setIsLeaveDialogOpen(false)}
        onSuccess={onClickLeave}
      />
    </ServiceWrapper>
  );
};

export default More;
