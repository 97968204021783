import React from 'react';
import styled from 'styled-components';
import { format } from '../utils/date';

// type Tstatus = '01' | '02' | '03';

// interface IBadge {
//   status: Tstatus;
// }

interface TicketPaymentCardProps {
  seasonTicket: string;
  remarks?: any;
  _id: string;
  payer: string;
  site: string;
  startAt: string;
  endAt: string;
  price: number;
  method: string;
  paidAt: string;
  createdAt: string;
  updatedAt: string;
}

const TicketPaymentCard: React.FC<TicketPaymentCardProps> = ({
  price,
  method,
  createdAt,
}) => {
  return (
    <ListBox>
      <ListContent>
        <Label>{method}</Label>
        <ListContentValue>{price.toLocaleString()}원</ListContentValue>
      </ListContent>
      <ListContent>
        {/* <ListContentMore>
          {startAt.slice(0, 10)} ~ {endAt.slice(0, 10)}
        </ListContentMore> */}
        <CreatedAt>{format(createdAt)}</CreatedAt>
      </ListContent>
    </ListBox>
  );
};

export default TicketPaymentCard;

const Label = styled.span`
  color: #a1a6ad;
  font-weight: 400;
`;

const ListBox = styled.div`
  display: flex;
  background-color: rgb(247, 246, 250);
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &:last-child {
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media screen and (max-width: 500px) {
    &:last-child {
      align-items: unset;
      justify-content: space-between;
    }
  }
`;

const ListContentValue = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
`;

// const ListContentMore = styled.span`
//   color: rgb(31, 40, 70);
//   /* text-decoration: underline; */
// `;

// const Badge = styled.span<IBadge>`
//   padding: 5px 8px;
//   border-radius: 50rem;
//   margin-left: 8px;
//   font-size: 0.9rem;
//   font-weight: normal;

//   ${({ status }) =>
//     status === '01' &&
//     css`
//       background-color: rgb(221, 222, 232);
//       color: rgb(31, 40, 70);
//     `}

//   ${({ status }) =>
//     status === '02' &&
//     css`
//       background-color: rgb(237, 228, 238);
//       color: rgb(150, 53, 46);
//     `}

//   ${({ status }) =>
//     status === '03' &&
//     css`
//       background-color: rgb(248, 241, 229);
//       color: rgb(218, 177, 112);
//     `}
// `;

const CreatedAt = styled.span`
  @media screen and (max-width: 500px) {
    margin-top: 8px;
    font-size: 0.9rem;
  }
`;
