import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import  {
  DayRange,
} from 'react-modern-calendar-datepicker';
import {
  Input,
  VStack,
  FormControl,
  FormLabel as Label,
  Text,
  Select,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import useSWR from 'swr';

import StackWrapper from '../../components/StackWrapper';
import CalendarRow from '../../components/CalendarRow';
import useAxios from '../../hooks/useAxios';
import AbsoluteButton from '../../components/AbsoluteButton';
import { PHONE_REGEX } from '../../utils/regex';
import { phoneNormalizer } from '../../utils/normalizers';
import { formStyle } from '../../theme';
import { startOfDay, endOfDay } from 'date-fns';

const ReservationView: React.FC = () => {
  const { reservationId } = useParams<{ reservationId: string }>();
  const { data } = useSWR(`/reservations/${reservationId}`);
  const axios = useAxios();
  const toast = useToast({
    title: '방문예약 정보 수정에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { errors, register, handleSubmit, reset, watch } = useForm({
    mode: 'onChange',
  });
  const { vehicleModel } = watch();

  const [selectedDay, setSelectedDay] = useState<DayRange>({
    from: null,
    to: null,
  });

  const onSubmit = async (data: any, e: any) => {
    if (loading) return;
    const { from, to } = selectedDay;
    setLoading(true);
    try {
      await axios.patch(`/reservations/${reservationId}`, {
        user: {
          name: data.name,
          phone: data.phone,
          company: data.company || undefined,
          department: data.department || undefined,
          position: data.position || undefined,
          vehicleModel:
            data.vehicleModel === '기타'
              ? data.otherVehicleModel
              : data.vehicleModel,
        },
        purpose: data.purpose || undefined,
        startAt:
          from && startOfDay(new Date(from.year, from.month - 1, from.day)).toISOString(),
        endAt: to && endOfDay(new Date(to.year, to.month - 1, to.day)).toISOString(),
      });
      toast({
        status: 'success',
        title: '성공',
        description: '수정이 완료되었습니다.',
      });
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message || '수정 중 오류가 발생했습니다.',
      });
    } finally {
      setIsEditable(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      reset({ ...data.user, ...data });
      setSelectedDay({
        from: {
          year: parseInt(data.startAt.slice(0, 4)),
          month: parseInt(data.startAt.slice(5, 7)),
          day: parseInt(data.startAt.slice(8, 10)),
        },
        to: {
          year: parseInt(data.endAt.slice(0, 4)),
          month: parseInt(data.endAt.slice(5, 7)),
          day: parseInt(data.endAt.slice(8, 10)),
        },
      });
    }
  }, [data, reset]);

  return (
    <StackWrapper title="방문예약 정보">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={6}>
          <FormControl>
            <FormLabel>차량 번호</FormLabel>
            <Input
              style={formStyle}
              name="vehicle"
              ref={register({ required: '차량 번호를 입력해 주세요!' })}
              placeholder="예) 12가1234, 123가4567"
              disabled={true}
            />
            {errors.vehicle && <Message>{errors.vehicle?.message}</Message>}
          </FormControl>
          <FormControl>
            <FormLabel>이름</FormLabel>
            <Input
              style={formStyle}
              name="name"
              ref={register({ required: '이름을 입력해 주세요!' })}
              disabled={!isEditable}
            />
            <FormLabel>*실명을 입력해주세요</FormLabel>
            {errors.name && <Message>{errors.name?.message}</Message>}
          </FormControl>
          {/* <FormControl>
      <FormLabel>회사</FormLabel>
      <Input style={formStyle} />
    </FormControl> */}
          <FormControl>
            <FormLabel>전화번호</FormLabel>
            <Input
              style={formStyle}
              name="phone"
              onChange={(e) => {
                e.target.value = phoneNormalizer(e.target.value);
              }}
              ref={register({
                pattern: {
                  value: PHONE_REGEX,
                  message: '잘못된 형식의 전화번호입니다.',
                },
              })}
              disabled={!isEditable}
            />
            {errors.phone && <Message>{errors.phone?.message}</Message>}
          </FormControl>
          <FormControl>
            <FormLabel>차량 종류</FormLabel>
            <Select
              style={formStyle}
              name="vehicleModel"
              ref={register}
              disabled={!isEditable}
            >
              <option value="">선택안함</option>
              <option value="경차">경차</option>
              <option value="세단">세단</option>
              <option value="SUV">SUV</option>
              <option value="버스">버스</option>
              <option value="트럭">트럭</option>
              <option value="탑차">탑차</option>
              <option value="기타">기타</option>
            </Select>
            {errors.vehicleModel && (
              <Message>{errors.vehicleModel?.message}</Message>
            )}
          </FormControl>
          {vehicleModel === '기타' && (
            <FormControl>
              <Input
                style={formStyle}
                name="otherVehicleModel"
                placeholder="차량 종류 직접 입력"
                ref={register({
                  required: '차량 종류를 직접 입력해 주세요!',
                })}
                disabled={!isEditable}
              />
              {errors.otherVehicleModel && (
                <Message>{errors.otherVehicleModel?.message}</Message>
              )}
            </FormControl>
          )}
          <FormControl>
            <FormLabel>방문목적</FormLabel>
            <Input
              style={formStyle}
              name="purpose"
              ref={register}
              disabled={!isEditable}
            />
          </FormControl>
          <FormControl>
            <FormLabel>회사</FormLabel>
            <Input
              style={formStyle}
              name="company"
              ref={register}
              disabled={!isEditable}
            />
          </FormControl>
          <FormControl>
            <FormLabel>부서</FormLabel>
            <Input
              style={formStyle}
              name="department"
              ref={register}
              disabled={!isEditable}
            />
          </FormControl>
          <FormControl>
            <FormLabel>직책</FormLabel>
            <Input
              style={formStyle}
              name="position"
              ref={register}
              disabled={!isEditable}
            />
          </FormControl>
          <FormControl>
            <FormLabel>방문일정</FormLabel>
            <CalendarRow
              isEditable={isEditable}
              value={selectedDay}
              onChange={setSelectedDay}
            />
          </FormControl>
          <FormControl>
            <FormLabel>할인권 등록</FormLabel>
            <Text>{data?.discountCouponKey?.name || '선택 안함'}</Text>
            {errors.discountCouponKey && (
              <Message>{errors.discountCouponKey?.message}</Message>
            )}
          </FormControl>

          <FormControl>
            <FormLabel>방문횟수 제한</FormLabel>
            <Text>{data?.visitLimit}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>누적 방문횟수</FormLabel>
            <Text>{data?.visitCount}</Text>
          </FormControl>
          <Alert status="warning">
            <AlertIcon />
            방문횟수 제한만큼 할인권이 차감됩니다. (ex: 2회로 제한 시 2개 차감)
          </Alert>
          {/* <FormControl>
            <FormLabel>후불결제 여부</FormLabel>
            <Switch disabled={true} />
          </FormControl> */}

          {/* <FormControl>
      <FormLabel>입차알림 설정</FormLabel>
      <Select style={formStyle} name="plateNumber">
        <option>예</option>
      </Select>
    </FormControl> */}
        </VStack>
      </form>
      {isEditable ? (
        <AbsoluteButton
          onClick={handleSubmit(onSubmit)}
          containerStyle={{ position: 'unset', marginTop: 32 }}
          isLoading={loading}
        >
          저장하기
        </AbsoluteButton>
      ) : (
        <AbsoluteButton
          containerStyle={{ position: 'unset', marginTop: 32 }}
          onClick={() => setIsEditable(true)}
        >
          정보 수정하기
        </AbsoluteButton>
      )}
    </StackWrapper>
  );
};

export default ReservationView;

const FormLabel: React.FC<any> = ({ children, ...props }) => (
  <Label
    color="#828687"
    fontSize="0.9rem"
    marginTop={1}
    marginBottom={1}
    {...props}
  >
    {children}
  </Label>
);

const Message = (props: any) => (
  <Text fontSize="xs" color="brand" marginTop={1} {...props} />
);