import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Heading,
  Text,
  useToast,
  Center,
  Spinner,
  Box,
  Tag,
  Flex,
  Divider,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
  VStack,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import useSWR from 'swr';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import ServiceWrapper from '../../components/ServiceWrapper';
import useAxios from '../../hooks/useAxios';
import { userInfoState } from '../../recoil/auth';
import AbsoluteButton from '../../components/AbsoluteButton';
import TicketPaymentCard from '../../components/TicketPaymentsCard';
import Dialog from '../../components/Dialog';
import { PRIVATE_ROUTE } from '../../routes/routes.constants';
import { useTossPayments } from '../../hooks/useTossPayments';

const Status = {
  '01': '신규',
  '02': '등록대기',
  '04': '사용중',
  '05': '사용종료',
  '06': '삭제대기',
  '07': '결제대기',
  '08': '후불결제대기',
} as const;

const S = {
  신규: '01',
  등록대기: '02',
  사용중: '04',
  사용종료: '05',
  삭제대기: '06',
  결제대기: '07',
  후불결제대기: '08',
} as const;

const SeasonTickets: FC = () => {
  const axios = useAxios();
  const history = useHistory();
  const tossPayments = useTossPayments();

  const toast = useToast({
    title: '정기권 신청에 실패했습니다.',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top',
  });
  const userInfo = useRecoilValue(userInfoState);

  const isSiliconBridge = useMemo(
    () => userInfo?.site.region === 'siliconbridge',
    [userInfo?.site.region]
  );

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data, error, isValidating, mutate } = useSWR('/season-tickets/me');

  const { data: ticketPaymentsData, mutate: mutatePayments } = useSWR(
    '/season-tickets/payments'
  );

  const deleted = useMemo(
    () => [S.삭제대기, S.사용종료].includes(data?.status),
    [data?.status]
  );

  const purchasable = useMemo(
    () => [S.결제대기, S.후불결제대기].includes(data?.status),
    [data?.status]
  );

  const transferable = useMemo(
    () => purchasable || [S.등록대기, S.사용중].includes(data?.status),
    [data?.status, purchasable]
  );

  const extendable = useMemo(
    () => [S.사용중].includes(data?.status),
    [data?.status]
  );

  const onSubmit = async (requestingMonths: number) => {
    if (loading) return;
    if (!userInfo?.vehicle?.plateNumber)
      return toast({ description: '차량 등록을 먼저 해주세요.' });
    const payload = {
      user: {
        name: userInfo?.info.name,
        email: userInfo?.email,
        phone: userInfo?.info.phone,
        department: userInfo?.info.department,
        position: userInfo?.info.position,
      },
      vehicleType: '사용자',
      vehicleModelName: userInfo?.vehicle?.modelName || undefined,
      vehicle: userInfo?.vehicle?.plateNumber,
      requestingMonths,
    };
    try {
      setLoading(true);
      await axios.post(
        `/season-tickets?siteId=${userInfo?.site?._id}`,
        payload
      );
      toast({
        status: 'success',
        title: '정기권 신청 완료',
        description: '정기권 신청이 완료되었습니다.',
      });
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message || '정기권 신청 중 오류가 발생했습니다.',
      });
    } finally {
      setLoading(false);
      mutate();
    }
  };

  const onClickExtend = useCallback(
    async (method: '토스페이' | '카드') => {
      if (!data || !tossPayments || loading) return;
      setLoading(true);

      try {
        const res = await axios.post(`/season-tickets/${data._id}/extend`);
        await tossPayments.requestPayment(method, res.data);
      } catch (err: any) {
        if (err.code !== 'USER_CANCEL') {
          toast({
            description:
              err?.response?.data?.message ||
              '정기권 연장 중 오류가 발생했습니다.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [axios, data, loading, toast, tossPayments]
  );

  const onClickCancel = useCallback(async () => {
    if (!data || loading) return;
    setLoading(true);

    await axios.post(
      `/season-tickets/${data._id}/${deleted ? 'reapply' : 'cancel'}`
    );

    mutate();
    setLoading(false);
    setIsDialogOpen(false);
  }, [axios, data, mutate, deleted, loading]);

  const onClickPay = useCallback(
    async (method: '토스페이' | '카드') => {
      if (!data || !tossPayments) return;
      try {
        const res = await axios.post(`/season-tickets/${data._id}/purchase`);
        await tossPayments.requestPayment(method, res.data);
      } catch (err: any) {
        if (err.code !== 'USER_CANCEL') {
          toast({
            description:
              err?.response?.data?.message ||
              '정기권 구매 중 오류가 발생했습니다.',
          });
        }
      }
    },
    [axios, data, toast, tossPayments]
  );

  useEffect(() => {
    mutate();
    mutatePayments();
  }, [mutate, mutatePayments]);

  const whiteButtonProps = {
    background: 'white',
    color: 'brand',
    borderColor: 'brand',
    variant: 'outline',
    _hover: { background: '#f8f9fa', borderColor: 'darkRed' },
    _active: { background: '#f8f9fa', borderColor: 'darkRed' },
  };

  return (
    <ServiceWrapper title="정기권">
      <Tabs isFitted colorScheme="brand">
        <TabList mb="1em">
          <Tab px={0}>신청내역</Tab>
          <Tab px={0}>결제내역</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {!data && !error && isValidating ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              <>
                <Box
                  style={{
                    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.08)',
                    borderRadius: '0.375rem',
                  }}
                  width="100%"
                  padding={6}
                >
                  <Flex paddingBottom={2} align="center">
                    <Tag marginRight={2}>
                      {error
                        ? '미신청'
                        : Status[data?.status as keyof typeof Status]}
                    </Tag>
                    <Heading fontSize="lg">
                      {error ? userInfo?.vehicle?.plateNumber : data?.vehicle}
                    </Heading>
                  </Flex>
                  <Divider my={2} />
                  <Text lineHeight={2}>
                    <strong>이름:</strong>{' '}
                    {error ? userInfo?.info?.name : data?.user?.name}
                    <br />
                    <strong>차량 구분:</strong>{' '}
                    {error ? '사용자' : data?.vehicleType}
                    <br />
                    <strong>차량 모델:</strong>{' '}
                    {error
                      ? userInfo?.vehicle?.modelName
                      : data?.vehicleModelName}
                    <br />
                    <strong>전화번호:</strong>{' '}
                    {error ? userInfo?.info.phone : data?.user?.phone}
                    <br />
                    <strong>부서:</strong>{' '}
                    {(error
                      ? userInfo?.info.department
                      : data?.user?.department) || '-'}
                    <br />
                    <strong>직책:</strong>{' '}
                    {(error ? userInfo?.info.position : data?.user?.position) ||
                      '-'}
                    {!error && !!data?.waitingSequence && (
                      <>
                        <br />
                        <strong>대기자 순번:</strong>{' '}
                        {data?.waitingSequence.toLocaleString()}
                      </>
                    )}
                  </Text>
                  {/* <strong>비고:</strong> {data?.remarks} */}
                  {(data?.startAt || data?.endAt) && (
                    <>
                      <Divider my={2} />
                      <Text lineHeight={2}>
                        <strong>시작일:</strong>{' '}
                        {data?.startAt
                          ? format(
                              new Date(data.startAt),
                              'yyyy-MM-dd HH:mm:ss'
                            )
                          : '-'}
                        <br />
                        <strong>만료일:</strong>{' '}
                        {data?.endAt
                          ? format(new Date(data.endAt), 'yyyy-MM-dd HH:mm:ss')
                          : '-'}
                      </Text>
                    </>
                  )}
                </Box>

                {error &&
                  (userInfo?.site.autoApproveSeasonTicket
                    ? [1, 3, 6]
                    : [1]
                  ).map((n) => (
                    <AbsoluteButton
                      key={n}
                      isLoading={loading}
                      onClick={() => onSubmit(n)}
                      containerStyle={{ position: 'unset', marginTop: 8 }}
                    >
                      {userInfo?.site.autoApproveSeasonTicket
                        ? `정기권 ${n}개월 신청하기`
                        : '정기권 신청하기'}
                    </AbsoluteButton>
                  ))}

                {!error && extendable && (
                  <>
                    <AbsoluteButton
                      isLoading={loading}
                      onClick={() => onClickExtend('카드')}
                      containerStyle={{ position: 'unset', marginTop: 8 }}
                    >
                      정기권 카드 연장하기
                    </AbsoluteButton>
                    <AbsoluteButton
                      isLoading={loading}
                      onClick={() => onClickExtend('토스페이')}
                      containerStyle={{ position: 'unset', marginTop: 8 }}
                    >
                      정기권 토스페이 연장하기
                    </AbsoluteButton>
                  </>
                )}

                {!error && (
                  <AbsoluteButton
                    isLoading={loading}
                    onClick={
                      deleted ? onClickCancel : () => setIsDialogOpen(true)
                    }
                    containerStyle={{ position: 'unset', marginTop: 8 }}
                    {...(deleted ? {} : whiteButtonProps)}
                  >
                    정기권 {deleted ? '재신청' : '취소'}하기
                  </AbsoluteButton>
                )}

                {!error &&
                  transferable &&
                  !isSiliconBridge && (
                    <AbsoluteButton
                      {...whiteButtonProps}
                      containerStyle={{ position: 'unset', marginTop: 8 }}
                      onClick={() =>
                        history.push(
                          `${PRIVATE_ROUTE.SEASON_TICKETS}/transfer/${data?._id}`
                        )
                      }
                    >
                      정기권 양도하기
                    </AbsoluteButton>
                  )}

                {!error && purchasable && (
                  <>
                    <AbsoluteButton
                      onClick={() => onClickPay('카드')}
                      containerStyle={{ position: 'unset', marginTop: 8 }}
                    >
                      정기권 카드 결제하기
                    </AbsoluteButton>
                    <AbsoluteButton
                      onClick={() => onClickPay('토스페이')}
                      containerStyle={{ position: 'unset', marginTop: 8 }}
                    >
                      정기권 토스페이 결제하기
                    </AbsoluteButton>
                  </>
                )}
              </>
            )}
          </TabPanel>
          <TabPanel>
            {(!ticketPaymentsData || !ticketPaymentsData.length) && (
              <Alert status="warning" marginBottom={8}>
                <AlertIcon />
                내역이 없습니다.
              </Alert>
            )}
            <VStack marginBottom={6}>
              {ticketPaymentsData?.map((ticket: any) => (
                <TicketPaymentCard
                  key={`ticket-payments-${ticket._id}`}
                  {...ticket}
                />
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Dialog
        title="정기권 취소"
        buttonText="확인"
        description="해당 정기권 취소 시, 금액 환불은 되지 않으며 복원 필요 시, 고객센터 및 센터를 통해 요청해야 합니다."
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={onClickCancel}
      />
    </ServiceWrapper>
  );
};

export default SeasonTickets;
