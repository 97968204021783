import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Input,
  VStack,
  FormControl,
  FormLabel as Label,
  Text,
  useToast,
  Alert,
  AlertIcon,
  Select,
} from '@chakra-ui/react';

import StackWrapper from '../../components/StackWrapper';
import AbsoluteButton from '../../components/AbsoluteButton';
import useAxios from '../../hooks/useAxios';
import { useLogout } from '../../recoil/auth';
import { VEHICLE_REGEX } from '../../utils/regex';
import { formStyle } from '../../theme';

const Vehicles: React.FC = () => {
  const axios = useAxios();
  const toast = useToast({
    title: '차량 등록에 실패했습니다.',
    status: 'error',
    duration: 6000,
    isClosable: true,
    position: 'top',
  });

  const logout = useLogout();
  const { errors, register, handleSubmit, watch } = useForm();
  const { modelName } = watch();

  const onSubmit = async (values: any) => {
    if (values?.plateNumber.match(VEHICLE_REGEX) === null) {
      toast({
        description: '차량번호를 제대로 입력해 주세요!',
      });
      return;
    }
    try {
      const { modelName, otherModelName, ...props }: any = values;
      await axios.put('/vehicle', {
        ...props,
        modelName: modelName === '기타' ? otherModelName : modelName,
      });
      toast({
        status: 'success',
        title: '성공',
        description:
          '차량 등록이 완료되었습니다. 관리자 승인 후 서비스 이용이 가능합니다.',
      });
      logout();
    } catch (err: any) {
      toast({
        description:
          err?.response?.data?.message || '등록 중 오류가 발생했습니다.',
      });
      return;
    }
  };

  return (
    <StackWrapper title="차량 등록">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={6}>
          <FormControl>
            <FormLabel>차량 번호</FormLabel>
            <Input
              style={formStyle}
              name="plateNumber"
              ref={register({ required: '차량 번호를 입력해 주세요!' })}
              placeholder="예) 12가1234, 123가4567"
            />
            {errors.plateNumber && (
              <Message>{errors.plateNumber?.message}</Message>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>차량 종류</FormLabel>
            <Select style={formStyle} name="modelName" ref={register}>
              <option value="">선택안함</option>
              <option value="경차">경차</option>
              <option value="세단">세단</option>
              <option value="SUV">SUV</option>
              <option value="버스">버스</option>
              <option value="트럭">트럭</option>
              <option value="탑차">탑차</option>
              <option value="기타">기타</option>
            </Select>
            {errors.modelName && <Message>{errors.modelName?.message}</Message>}
          </FormControl>
          {modelName === '기타' && (
            <FormControl>
              <Input
                style={formStyle}
                name="otherModelName"
                placeholder="차량 종류 직접 입력"
                ref={register({
                  required: '차량 종류를 직접 입력해 주세요!',
                })}
              />
              {errors.otherModelName && (
                <Message>{errors.otherModelName?.message}</Message>
              )}
            </FormControl>
          )}
          <Alert status="warning">
            <AlertIcon />
            차량 등록을 하면 즉시 로그아웃됩니다.
            <br /> 관리자가 승인하기 전까지 서비스 이용이 불가능합니다.
          </Alert>
        </VStack>
        <AbsoluteButton type="submit">등록하기</AbsoluteButton>
      </form>
    </StackWrapper>
  );
};

export default Vehicles;

const FormLabel: React.FC<any> = ({ children, ...props }) => (
  <Label color="#828687" fontSize="0.9rem" marginBottom={1} {...props}>
    {children}
  </Label>
);

const Message = (props: any) => (
  <Text fontSize="xs" color="brand" marginTop={1} {...props} />
);
