import { useState, useEffect, MutableRefObject } from 'react';

function useOnScreen(ref: MutableRefObject<Element | null>, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      { rootMargin }
    );

    const { current } = ref;
    current && observer.observe(current);
    return () => void (current && observer.unobserve(current));
  }, [ref, rootMargin]);

  return isIntersecting;
}

export default useOnScreen;
