export const PUBLIC_ROUTE = {
  ROOT: '/',
  LOGIN: '/login',
  LANDING: '/',
  PAGE_404: '/404',
  PAGE_500: '/500',
  SIGNUP: '/signup',
  EMAIL_VERIFY: '/email-verify',
  RECOVER: '/recover',
  RESET_PASSWORD: '/reset-password',
  PRIVACY_POLICY: '/privacy-policy',
};

export const PRIVATE_ROUTE = {
  MAIN: '/main',
  PROFILE: '/profile',
  PROFILE_PASSWORD: '/profile-password',

  CALCULATE: '/calculate',
  DISCOUNT_COUPONS: '/discount-coupons',
  SEASON_TICKETS: '/season-tickets',
  RESERVATIONS: '/reservations',
  RESERVATION_SUBMIT: '/reservation-submit',
  MORE: '/more',

  NOTICES: '/notices',
  FEEDBACKS: '/feedbacks',
  VEHICLES: '/vehicles',

  PAYMENT_SUCCESS: '/payment-success',
  PAYMENT_CANCEL: '/payment-cancel',
};
